/*	module - collage
=========================================================================== */

.nm-md-collage {
	margin: 0 auto 60px;
	position: relative;
}

.nm-md-collage-wrap {
	padding-top: 60px;
	position: relative;
}

.nm-md-collage-media {
	display: table;
	margin: 0 4%;
	position: relative;
	vertical-align: bottom;
	width: 92%;
	z-index: 6;
}

.nm-md-collage-bg {
	display: block;
	height: 0;
	margin-top: -130px;
	padding-bottom: 56.25%;
	position: relative;
	width: 100%;
}

.nm-md-collage-img > div,
.nm-md-collage-bg > div {
	background-color: transparent;
	background-position: top left;
	background-repeat: no-repeat;
	background-size: cover;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

.nm-md-collage-bg .nm-mediaplayer {
	height: 0;
}

.nm-md-collage-content {
	display: table-cell;
	position: relative;
	vertical-align: bottom;
	width: 100%;
}

.nm-md-collage-copy {
	background-color: #fff;
	box-sizing: border-box;
	display: inline-block;
	margin: 0 4% 60px 0;
	padding: 25px 30px;
	position: relative;
	width: 52%;
	z-index: 5;
}

.nm-md-collage-copy > .nm-el-lk {
	margin-top: 12px;
}

.nm-md-collage-copy-inverted {
	background-color: #000;
	color: #fff;
}

.nm-md-collage-img-wrap {
	bottom: 0;
	left: 52%;
	position: absolute;
	width: 48%;
	z-index: 6;
}

.nm-md-collage-img-wrap .nm-mediaplayer {
	background-color: #fff;
}

.nm-md-collage-img {
	display: block;
	height: 0;
	padding-bottom: 56.25%;
	position: relative;
	width: 100%;
}

.nm-md-collage-type-1 .nm-md-collage-copy {
	margin: 0 0 60px 48%;
	z-index: 6;
}

.nm-md-collage-type-1 .nm-md-collage-img-wrap {
	left: 0;
	width: 52%;
	z-index: 5;
}

.nm-md-collage-type-2 .nm-md-collage-wrap {
	padding-top: 0;
}

.nm-md-collage-type-2 .nm-md-collage-bg {
	margin-top: 0;
	position: absolute;
	top: 0;
}

.nm-md-collage-type-2 .nm-md-collage-media {
	padding-top: 56.25%;
}

.nm-md-collage-type-2 .nm-md-collage-content {
	display: block;
	top: -130px;
}

.nm-md-collage-type-2 .nm-md-collage-content,
.nm-md-collage-type-2 .nm-md-collage-media {
	vertical-align: top;
}

.nm-md-collage-type-2 .nm-md-collage-img-wrap {
	bottom: auto;
	top: 0;
}

.nm-md-collage-type-2 .nm-md-collage-copy {
	margin-top: 60px;
}

.nm-md-collage .nm-button-overlay {
	position: absolute;
	top: calc(50% - 27px); /* top: 48%; */
}

.nm-md-collage .nm-md-collage-copy .nm-el-hdl-03 {
	margin-bottom: 7px;
}

.nm-md-collage .nm-button-play a,
.nm-md-collage .nm-button-play button {
	height: 55px;
	width: 55px;
}

.nm-md-collage .nm-mediaplayer .nm-button-overlay {
	z-index: 6;
}

@media all and (max-width: 689px) {
	.nm-state-is-responsive .nm-md-collage-wrap {
		padding-top: 0;
	}

	.nm-state-is-responsive .nm-md-collage-type-2 .nm-md-collage-media {
		padding-top: 0;
	}

	.nm-state-is-responsive .nm-md-collage-type-2 .nm-md-collage-content {
		top: auto;
	}

	.nm-state-is-responsive .nm-md-collage-media {
		display: block;
		margin: 0;
		vertical-align: top;
		width: 100%;
	}

	.nm-state-is-responsive .nm-md-collage-img-wrap,
	.nm-state-is-responsive .nm-md-collage-copy {
		width: 100%;
	}

	.nm-state-is-responsive .nm-md-collage-content {
		display: block;
		padding: 112.5% 0 0;
		vertical-align: top;
	}

	.nm-state-is-responsive .nm-md-collage-wrap-no-img .nm-md-collage-content {
		padding-top: 56.25%;
	}

	.nm-state-is-responsive .nm-md-collage-wrap-no-img .nm-md-collage-bg {
		margin-top: 0;
	}

	.nm-state-is-responsive .nm-md-collage-img-wrap {
		bottom: auto;
		display: block;
		left: 0;
		position: absolute;
		top: 0;
	}

	.nm-state-is-responsive .nm-md-collage-copy {
		display: block;
		margin: 0;
		padding: 20px 0 0;
	}

	.nm-state-is-responsive .nm-md-collage-copy-inverted {
		background-color: #fff;
		color: #000;
	}

	.nm-state-is-responsive .nm-md-collage-bg {
		margin-top: 56.25%;
		position: absolute;
		top: 0;
	}

	.nm-state-is-responsive .nm-md-collage-img-wrap > .nm-md-collage-bg {
		margin-top: 0;
	}
}
