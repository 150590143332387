.nm-numbers-teaser {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}

.nm-numbers-teaser p {
	margin: 0;
	padding: 0;
	z-index: 1;
}

.nm-numbers-teaser-hide-margin {
	margin-top: -80px;
}

.nm-numbers-teaser-hide-margin:first-of-type {
	margin-top: 0;
}

.nm-numbers-teaser .numbers-teaser-column {
	align-items: center;
	background-color: #f2f2f2;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;
}

.nm-numbers-teaser[data-theme="dark"] .numbers-teaser-column {
	background-color: #3c3c3c;
}

.nm-numbers-teaser[data-theme="bright"] .numbers-teaser-column {
	background-color: #fff;
}

.nm-numbers-teaser ul::after {
	border-bottom: 1px solid #d6d6d6;
	content: "";
	width: 84%;
}

.nm-numbers-teaser[data-theme="dark"] ul::after {
	border-bottom: 1px solid #595959;
}

.nm-numbers-teaser ul:nth-last-child(1)::after {
	border: 0;
}

.nm-numbers-teaser .numbers-teaser-column li {
	align-items: flex-end;
	display: flex;
	justify-content: center;
	width: 66%;
}

.numbers-teaser-unit {
	padding-left: 5px;
}

.nm-numbers-teaser .numbers-teaser-column .numbers-teaser-title {
	padding-top: 25px;
}

.nm-numbers-teaser .numbers-teaser-column .numbers-teaser-figure {
	padding-bottom: 20px;
	padding-top: 20px;
}

.nm-numbers-teaser .numbers-teaser-column .numbers-teaser-description {
	padding-bottom: 25px;
}

/* Fonts */
.numbers-teaser-font-title {
	color: #8c8c8c;
	font: normal 10px/21px AudiTypeWide, Verdana, sans-serif;
	font-weight: var(--font-weight-light);
	letter-spacing: 2.5px;
	text-align: center;
}

.nm-numbers-teaser[data-theme="dark"] .numbers-teaser-font-title {
	color: #808080;
}

.numbers-teaser-font-figure {
	color: #000;
	font: normal 36px/36px AudiTypeWide, Verdana, sans-serif;
	font-weight: var(--font-weight-light);
}

.nm-numbers-teaser p.numbers-teaser-font-figure {
	letter-spacing: -3px;
	margin-right: 4px;
}

.nm-numbers-teaser[data-theme="dark"] .numbers-teaser-font-figure {
	color: #fff;
}

.numbers-teaser-font-description {
	color: #8c8c8c;
	font: normal 12px/21px AudiTypeWide, Verdana, sans-serif;
	font-weight: var(--font-weight-light);
	text-align: center;
}

.nm-numbers-teaser[data-theme="dark"] .numbers-teaser-font-description {
	color: #808080;
}

.numbers-teaser-font-unit {
	color: #000;
	font: normal 12px/16px AudiTypeWide, Verdana, sans-serif;
	font-weight: var(--font-weight-light);
	padding-bottom: 0 !important;
	text-align: left;
}

.nm-numbers-teaser[data-theme="dark"] .numbers-teaser-font-unit {
	color: #fff;
}

/* End Fonts */

@media screen and (min-width: 590px) {
	.nm-numbers-teaser .numbers-teaser-column {
		position: relative;
		width: 33.33%;
	}

	.nm-numbers-teaser ul::after {
		border-bottom: 0;
		border-right: 1px solid #d6d6d6;
		height: 84%;
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
	}

	.nm-numbers-teaser[data-theme="dark"] ul::after {
		border-bottom: 0;
		border-right: 1px solid #595959;
	}
}

@media screen and (min-width: 710px) {
	.numbers-teaser-font-title {
		letter-spacing: 3.5px;
	}

	.numbers-teaser-font-figure {
		font-size: 44px;
		line-height: 44px;
	}
}

@media screen and (min-width: 860px) {
	.numbers-teaser-font-title {
		font-size: 12px;
		line-height: 21px;
	}

	.nm-numbers-teaser p.numbers-teaser-font-figure {
		margin-right: 7px;
	}
}

@media screen and (max-width: 1035px) {
	.numbers-teaser-font-unit {
		font-size: 13px;
		line-height: 24px;
	}

	.numbers-teaser-font-unit {
		font-size: 13px;
		line-height: 16px;
	}
}

@media screen and (min-width: 1035px) {
	.numbers-teaser-font-title {
		font-size: 13px;
		line-height: 21px;
	}

	.numbers-teaser-font-figure {
		font-size: 60px;
		line-height: 60px;
	}

	.numbers-teaser-font-unit {
		font-size: 14px;
		line-height: 16px;
		padding-bottom: 5px !important;
	}
}

@media screen and (max-width: 1280px) {
	.numbers-teaser-font-unit {
		font-size: 15px;
		line-height: 21px;
	}
}

@media screen and (min-width: 1280px) {
	.numbers-teaser-font-figure {
		font-size: 74px;
		line-height: 74px;
	}

	.numbers-teaser-font-unit {
		font-size: 21px;
		line-height: 25px;
	}
}
