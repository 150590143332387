.nm-expandable {
	border-bottom: 1px solid var(--color-grey-10);
	border-top: 1px solid var(--color-grey-10);
	box-sizing: border-box;
	cursor: pointer;
	display: block;
	margin: 0;
	padding: var(--space-l);
	width: 100%;
}

.nm-expandable[is-open=false] + script + .nm-module + .nm-expandable,
.nm-expandable[is-open=false] + script + [data-module] + .nm-expandable {
	border-top: none;
}

.nm-expandable + script + .nm-module + .nm-module,
.nm-expandable + script + [data-module] + [data-module] {
	clear: both; /* if the nm-module above is layouted with float, the label shoudn't be affected */
}

.nm-expandable + script + .nm-module,
.nm-expandable + script + [data-module] {
	margin: 0;
	max-height: 0;
	opacity: 0;
	overflow: hidden;
	padding-bottom: 0;
	transition: max-height 420ms ease-in-out, opacity 420ms ease-in-out, padding 420ms ease-in-out;
}

.nm-expandable:first-of-type,
.nm-module:not(.nm-expandable) + .nm-module:not(.nm-expandable) + .nm-expandable,
[data-module]:not(.nm-expandable) + [data-module]:not(.nm-expandable) + .nm-expandable {
	margin-top: var(--space-xl);
}

.nm-expandable[is-open=false] + script + *:last-child,
.nm-expandable[is-open=true]:last-of-type + script + .nm-module,
.nm-expandable[is-open=true]:last-of-type + script + [data-module] {
	margin-bottom: var(--space-xxxl);
}

.nm-expandable + script + .nm-module + .nm-module:not(.nm-expandable),
.nm-expandable + script + [data-module] + [data-module]:not(.nm-expandable) {
	margin-top: var(--space-xxxl);
}

/* this rule should be removed once new CI is completely implemented and nm-el-pg is no longer in use */
.nm-el-pg + .nm-module.nm-expandable {
	margin-top: 0;
}

.nm-expandable__click-area {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
}

.nm-expandable__label {
	font-weight: var(--font-weight-bold);
	width: calc(100% - 24px - var(--space-l));
}

.nm-expandable__toggle-icon {
	align-self: flex-start;
	height: 30px;
	margin-left: var(--space-l);
	transition: transform 420ms ease-in-out;
	width: 24px;
}

.nm-expandable[is-open=true] .nm-expandable__toggle-icon {
	transform: rotate(180deg);
	transition: transform 240ms ease-in-out 120ms;
}

.nm-expandable[is-open=true] + script + .nm-module,
.nm-expandable[is-open=true] + script + [data-module] {
	max-height: 30000px; /* arbitrary value */
	opacity: 1;
	overflow: hidden;
	text-overflow: ellipsis;
	transition: max-height 420ms ease-in-out, opacity 420ms ease-in-out, padding 420ms ease-in-out;
}

/* module specific style settings in combination with expandable */
.nm-expandable + script + .nm-md-link-list,
.nm-expandable + script + .nm-md-content-list,
.nm-expandable + script + .nm-module-text-html,
.nm-expandable + script + .nm-module-textblock-large {
	background: var(--color-grey-05);
	padding: 0 var(--space-l);
}

.nm-expandable[is-open=true] + script + .nm-md-link-list,
.nm-expandable[is-open=true] + script + .nm-md-content-list,
.nm-expandable[is-open=true] + script + .nm-module-text-html,
.nm-expandable[is-open=true] + script + .nm-module-textblock-large {
	padding: var(--space-l);
}

[page-type=widescreen] .nm-expandable[is-open=true] + script + .nm-module-text-html,
[page-type=widescreen] .nm-expandable[is-open=true] + script + .nm-module-textblock-large {
	margin: 0;
	padding-right: calc(100% - 66% - var(--space-l));
	width: 66%;
}

[page-type=widescreen] .nm-expandable + script + .nm-content-paragraph {
	margin-left: auto;
	margin-right: auto;
}

.nm-expandable + script + .nm-md-link-list *:last-child,
.nm-expandable + script + .nm-md-content-list *:last-child,
.nm-expandable + script + .nm-module-text-html *:last-child,
.nm-expandable + script + .nm-module-textblock-large *:last-child {
	margin-bottom: 0;
}

/* summary-options specific styles */
.nm-expandable + script + .nm-summary-options-list {
	background: var(--color-grey-05);
	border: none;
	display: block;
	max-height: 0;
	opacity: 0;
	overflow: hidden;
	padding-bottom: 0;
	transition: all 420ms ease-in-out;
}

.nm-expandable[is-open=true] + script + .nm-summary-options-list {
	max-height: 7000px;
	opacity: 1;
	overflow: visible;
	transition: all 240ms ease-in-out 120ms;
}

@media all and (min-width: 620px) {
	[page-type=widescreen] .nm-3col .nm-expandable {
		margin-left: var(--space-side-margin);
		margin-right: var(--space-side-margin);
		width: calc(100% - 2 * var(--space-side-margin));
	}

	[page-type=widescreen] .nm-3col .nm-expandable + script + .nm-module-text-html,
	[page-type=widescreen] .nm-3col .nm-expandable + script + .nm-module-textblock-large,
	[page-type=widescreen] .nm-3col .nm-expandable + script + .nm-content-list,
	[page-type=widescreen] .nm-3col .nm-expandable + script + .nm-md-link-list {
		box-sizing: border-box;
		margin-left: var(--space-side-margin);
		margin-right: var(--space-side-margin);
		width: calc(100% - 2 * var(--space-side-margin));
	}

	[page-type=widescreen] .nm-3col .nm-expandable + script + .nm-module-text-html,
	[page-type=widescreen] .nm-3col .nm-expandable + script + .nm-module-textblock-large {
		padding-right: calc(100% - 66% - var(--space-l));
	}
}
