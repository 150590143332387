/* 	360° Panorama - Layout - mobileFirst */
.nm-module.nm-module-panorama {
	margin-bottom: 0;
}

.nm-module-panorama[data-theme="dark"] {
	background-color: #000;
}

.nm-module-panorama .nm-module-panorama-content-wrapper {
	padding: var(--space-xxl) var(--space-column) var(--space-xxxl);
	width: calc(23 * var(--space-column));
}

.nm-module-panorama-wrapper {
	margin: var(--space-m) 0;
	position: relative;
	width: 100%;
}

.nm-module-panorama__headline {
	font-weight: var(--font-weight-bold);
}

.nm-module-panorama-wrapper .nm-button-close-fullscreen {
	display: none;
}

.nm-module-panorama-wrapper .nm-icon-cancel {
	margin-left: 11px;
	margin-top: 12px;
}

.nm-module-panorama-wrapper[data-state="active"] canvas {
	cursor: move;
}

.nm-module-panorama-wrapper[data-state="active"] .nm-button-close-fullscreen {
	background-color: #fff;
	border-radius: 99em;
	cursor: pointer;
	display: block;
	height: 54px;
	position: fixed;
	right: var(--space-m);
	top: var(--space-m);
	width: 54px;
	z-index: 11;
}

.nm-overlay-activate-panorama {
	cursor: pointer;
	display: none;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

.nm-overlay-activate-panorama[data-state="enabled"] {
	display: block;
}

.nm-button-activate-panorama {
	background-color: #fff;
	border-radius: 99em;
	height: 54px;
	left: calc(50% - 27px); /* center the circle : parentcontainer_height - circle_radius */
	position: absolute;
	top: calc(50% - 27px); /* center the circle : parentcontainer_width - circle_radius */
	width: 54px;
}

.nm-module-panorama-wrapper[data-state="active"] {
	height: 100%;
	left: 0;
	position: fixed;
	top: -18px;
	width: 100%;
	z-index: 100;
}

.nm-module-panorama-wrapper[data-state="active"] .nm-module-panorama-static,
.nm-module-panorama-wrapper[data-state="active"] .nm-overlay-activate-panorama {
	display: none;
}

.nm-button-activate-panorama .nm-icon-360-degrees {
	height: var(--icon-size-large);
	margin: 3px 0 0 3px;
	width: var(--icon-size-large);
}

.nm-module-panorama__copy,
.nm-module-panorama__list,
.nm-module-panorama__footnote-copy {
	margin-top: var(--space-m);
}

.nm-module-panorama .nm-module-panorama__cta-button {
	margin-top: var(--space-l);
}

.nm-module-panorama .nm-module-panorama-wrapper + .nm-module-panorama__cta-button {
	margin-top: 0;
}

@media all and (min-width: 768px) {
	.nm-button-activate-panorama {
		height: 96px;
		left: calc(50% - 48px);
		top: calc(50% - 48px); /* center the circle : update circle_radius */
		width: 96px;
	}

	.nm-button-activate-panorama .nm-icon-360-degrees {
		height: var(--icon-size-large);
		margin: 24px 0 0 24px;
		width: var(--icon-size-large);
	}
}

@media all and (min-width: 1440px) {
	.nm-module-panorama .nm-module-panorama-content-wrapper {
		margin: 0 auto;
		max-width: calc(19 * var(--space-column));
		padding-left: calc(3 * var(--space-column));
		padding-right: calc(3 * var(--space-column));
	}
}

@media all and (min-width: 1920px) {
	.nm-module-panorama .nm-module-panorama-content-wrapper {
		padding-left: 0;
		padding-right: 0;
	}
}
