
.modal-layer .nm-ie11-alert-layer {
	padding: 55px 8% 80px;
	width: auto;
}

.nm-ie11-alert-layer .modal-layer__inner-content {
	margin: 0 auto;
}

.nm-ie11-alert-layer .nm-button-cancel {
	margin-top: var(--space-xxl);
}
