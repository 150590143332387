/* Teaser Grid: Base elements
   -------------------------------------------------------- */

/* Icons */
.mf-doulechevron {
	background: transparent url("../assets/img/doublechevron.png") no-repeat;
	display: inline;
	line-height: 20px;
	margin-left: 10px;
	min-height: 20px;
	vertical-align: baseline;
	width: auto;
}

/*  Teasergrid
   -------------------------------------------------------- */

.mf-teasergrid {
	margin: 40px auto 0; /* margin-top is an initial value, will be adapted by javascript in publish mode to match 0.0391 * width */
	max-width: 1280px;
	overflow: hidden;
	width: 100%;
}

.mf-teasergrid-teaser {
	background-color: #e7e9ea;
	background-size: cover;
	float: left;
	left: 0;
	margin: 0 0 0 4%;
	max-height: 240px;
	max-width: 360px;
	overflow: hidden;
	position: relative;
	right: 0;
	vertical-align: top;
	width: 28%;
}

.mf-teasergrid-teaser::before {
	content: "";
	display: block;
	height: 0;
	padding-top: 72%;
	width: 100%;
}

.mf-teasergrid-teaser-text {
	border: 0;
	left: 0;
	margin: 0;
	overflow: hidden;
	padding: 20px 20px 0;
	position: absolute;
	top: 0;
}

.nm-link:hover .mf-teasergrid-teaser-text {
	color: var(--color-progressive-red);
}

.mf-teasergrid-error {
	color: #f00;
}

.mf-teasergrid-error ul li {
	list-style: disc;
	margin: 0 20px;
}

/* Edit mode */

.cq-wcm-edit .mf-teasergrid .cqInvalidLinkPrefix,
.cq-wcm-design .mf-teasergrid .cqInvalidLinkPrefix {
	margin-bottom: -11px;
	padding-left: 10px;
}

.cq-wcm-edit .mf-teasergrid .cqInvalidLinkSuffix,
.cq-wcm-design .mf-teasergrid .cqInvalidLinkSuffix {
	margin-bottom: -11px;
}

@media screen and (max-width: 749px) {
	.nm-state-is-responsive .mf-teasergrid-teaser {
		margin-bottom: 30px;
		width: 44%;
	}

	.nm-state-is-responsive .mf-teasergrid-teaser-text {
		padding: 20px 20px 0;
	}
}

@media screen and (max-width: 479px) {
	/* teasergrid */
	.nm-state-is-responsive .mf-teasergrid-teaser {
		float: none;
		margin: 0 auto 50px;
		width: 92%;
	}
}
