/* news teaser */

.nm-md-teaser-news .nm-date {
	display: block;
}

.nm-1col .nm-md-teaser-news .nm-nit img {
	margin: 0 0 15px; /* image works without floating in sidebar */
	width: 100%;
}

.nm-2col .nm-md-teaser-news .nm-nit img,
.nm-3col .nm-md-teaser-news .nm-nit img {
	float: left; /* it floats in main content */
	margin: 0 30px 0 0;
}
