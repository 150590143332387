.nm-breadcrumb {
	align-items: center;
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	position: relative;
}

.nm-breadcrumb__current-item,
.nm-breadcrumb__link {
	display: none;
}

.nm-breadcrumb__current-item {
	color: var(--color-grey-50);
}

.nm-breadcrumb__link:last-of-type {
	display: inline-flex;
}

.nm-breadcrumb__link:last-of-type::before {
	content: "<";
	margin-right: var(--space-xs);
}

.nm-layer-fallback .nm-layer-content > .nm-breadcrumb,
[page-type="widescreen"] .nm-breadcrumb {
	margin: 0 16px;
}

.nm-breadcrumb:first-child,
.nm-breadcrumb:last-child {
	margin-bottom: var(--space-xxl);
}

[page-type="widescreen"] .nm-breadcrumb:first-child {
	margin-bottom: 0;
	margin-top: var(--space-xxl);
}

@media all and (min-width: 768px) {
	.nm-breadcrumb__current-item,
	.nm-breadcrumb__link {
		display: inline-flex;
	}

	.nm-breadcrumb__link:last-of-type::before {
		content: none;
	}

	.nm-breadcrumb__link:last-of-type {
		display: inline-block;
	}

	.nm-breadcrumb__link::after {
		content: ">";
		margin-left: var(--space-xs);
	}

	.nm-breadcrumb__link {
		margin-right: var(--space-xs);
	}
}

@media (min-width: 375px) {
	.nm-layer-fallback .nm-layer-content > .nm-breadcrumb,
	[page-type="widescreen"] .nm-breadcrumb {
		margin: 0 28px;
	}
}

@media (min-width: 768px) {
	.nm-layer-fallback .nm-layer-content > .nm-breadcrumb,
	[page-type="widescreen"] .nm-breadcrumb {
		margin: 0 40px;
	}
}

@media (min-width: 1024px) {
	.nm-layer-fallback .nm-layer-content > .nm-breadcrumb,
	[page-type="widescreen"] .nm-breadcrumb {
		margin: 0 60px;
	}
}

@media (min-width: 1440px) {
	.nm-layer-fallback .nm-layer-content > .nm-breadcrumb,
	[page-type="widescreen"] .nm-breadcrumb {
		margin: 0 96px;
	}
}
