/**************************************************************************
	Media Gallery as Teaser on Content
**************************************************************************/

.nm-module-wrap .nm-module.nm-mediagallery-teaser,
.nm-mediagallery-teaser {
	width: 100%;
	/* 750 + 18px = 768/750 (width 2col) */
}

.nm-md-mediagallery-list {
	margin-bottom: 17px;
	position: relative;
}

.nm-mediagallery-teaser .nm-mediagallery-item {
	float: left;
	max-width: 100%;
	min-width: 33.3333%;
	position: relative;
	width: 100%;
}

.nm-mediagallery-teaser .nm-mediagallery-item > a {
	background-repeat: no-repeat;
	background-size: cover;
	display: block;
	height: 0;
	padding-bottom: 56.25%;
	position: relative;
	width: 100%;
}

.nm-md-mediagallery-list-items-2 .nm-md-mediagallery-item,
.nm-md-mediagallery-list-items-4 .nm-md-mediagallery-item {
	width: 50%;
}

.nm-md-mediagallery-list-items-3 .nm-md-mediagallery-item,
.nm-md-mediagallery-list-items-5 .nm-md-mediagallery-item,
.nm-md-mediagallery-list-items-6 .nm-md-mediagallery-item {
	width: 33.3333%;
}

.nm-md-mediagallery-list-items-3 .nm-md-mediagallery-item-2,
.nm-md-mediagallery-list-items-6 .nm-md-mediagallery-item-2 {
	float: right;
	width: 66.6666%;
}

.nm-md-mediagallery-list-items-5 .nm-md-mediagallery-item-1,
.nm-md-mediagallery-list-items-5 .nm-md-mediagallery-item-2 {
	width: 50%;
}

.nm-md-mediagallery-list-items-4 .nm-md-mediagallery-item-3,
.nm-md-mediagallery-list-items-4 .nm-md-mediagallery-item-4,
.nm-md-mediagallery-list-items-5 .nm-md-mediagallery-item-3,
.nm-md-mediagallery-list-items-5 .nm-md-mediagallery-item-4,
.nm-md-mediagallery-list-items-5 .nm-md-mediagallery-item-5,
.nm-md-mediagallery-list-items-6 .nm-md-mediagallery-item-4,
.nm-md-mediagallery-list-items-6 .nm-md-mediagallery-item-5,
.nm-md-mediagallery-list-items-6 .nm-md-mediagallery-item-6 {
	margin-top: -1px;
}

.nm-mediagallery-teaser .nm-c2-b {
	position: relative;
	top: -2px;
}

.nm-mediagallery-teaser .nm-mediagallery-item .nm-button-overlay {
	display: block;
	left: 50%;
	position: absolute;
	top: 50%;
	z-index: 2;
}

.nm-mediagallery-teaser .nm-mediagallery-item .nm-button-overlay > a {
	height: 70px;
	margin-left: -35px;
	margin-top: -35px;
	padding-bottom: 0;
}

.nm-state-is-responsive .nm-2col .nm-mediagallery-teaser .nm-mediagallery-item .nm-button-overlay > a {
	height: 50px;
	margin-left: -25px;
	margin-top: -25px;
}

.nm-state-is-responsive .nm-2col .nm-mediagallery-teaser .nm-mediagallery-item .nm-button-play a,
.nm-state-is-responsive .nm-2col .nm-mediagallery-teaser .nm-mediagallery-item .nm-button-play button {
	width: 50px;
}

.nm-state-is-responsive .nm-1col .nm-mediagallery-teaser .nm-mediagallery-item .nm-button-overlay > a {
	height: 30px;
	margin-left: -15px;
	margin-top: -15px;
}

.nm-state-is-responsive .nm-1col .nm-mediagallery-teaser .nm-mediagallery-item .nm-button-play a,
.nm-state-is-responsive .nm-1col .nm-mediagallery-teaser .nm-mediagallery-item .nm-button-play button {
	width: 30px;
}

.nm-state-is-responsive .nm-1col .nm-mediagallery-teaser .nm-mediagallery-item .nm-button-play a span {
	background-size: 50%;
}

/* middle sized play button */
.nm-mediagallery-teaser .nm-md-mediagallery-list-items-2 .nm-mediagallery-item .nm-button-overlay > a,
.nm-mediagallery-teaser .nm-md-mediagallery-list-items-4 .nm-mediagallery-item .nm-button-overlay > a,
.nm-mediagallery-teaser .nm-md-mediagallery-list-items-5 .nm-mediagallery-item .nm-button-overlay > a {
	height: 50px;
	margin-left: -25px;
	margin-top: -25px;
	width: 50px;
}

@media screen and (max-width: 599px) {
	.nm-state-is-responsive .nm-md-mediagallery-list-items-3 .nm-md-mediagallery-item-1,
	.nm-state-is-responsive .nm-md-mediagallery-list-items-5 .nm-md-mediagallery-item-1 {
		width: 100%;
	}

	.nm-state-is-responsive .nm-md-mediagallery-list-items-3 .nm-md-mediagallery-item-2,
	.nm-state-is-responsive .nm-md-mediagallery-list-items-3 .nm-md-mediagallery-item-3,
	.nm-state-is-responsive .nm-md-mediagallery-list-items-5 .nm-md-mediagallery-item-2,
	.nm-state-is-responsive .nm-md-mediagallery-list-items-5 .nm-md-mediagallery-item-3,
	.nm-state-is-responsive .nm-md-mediagallery-list-items-5 .nm-md-mediagallery-item-4,
	.nm-state-is-responsive .nm-md-mediagallery-list-items-5 .nm-md-mediagallery-item-5,
	.nm-state-is-responsive .nm-md-mediagallery-list-items-6 .nm-md-mediagallery-item-4,
	.nm-state-is-responsive .nm-md-mediagallery-list-items-6 .nm-md-mediagallery-item-5,
	.nm-state-is-responsive .nm-md-mediagallery-list-items-6 .nm-md-mediagallery-item {
		display: block;
		float: left;
		width: 50%;
	}

	.nm-state-is-responsive .nm-md-mediagallery-list-items-4 .nm-md-mediagallery-item-3,
	.nm-state-is-responsive .nm-md-mediagallery-list-items-4 .nm-md-mediagallery-item-4,
	.nm-state-is-responsive .nm-md-mediagallery-list-items-5 .nm-md-mediagallery-item-3,
	.nm-state-is-responsive .nm-md-mediagallery-list-items-5 .nm-md-mediagallery-item-4,
	.nm-state-is-responsive .nm-md-mediagallery-list-items-5 .nm-md-mediagallery-item-5,
	.nm-state-is-responsive .nm-md-mediagallery-list-items-6 .nm-md-mediagallery-item-4,
	.nm-state-is-responsive .nm-md-mediagallery-list-items-6 .nm-md-mediagallery-item-5,
	.nm-state-is-responsive .nm-md-mediagallery-list-items-6 .nm-md-mediagallery-item-6 {
		margin-top: 0;
	}

	.nm-state-is-responsive .nm-2col .nm-mediagallery-teaser .nm-mediagallery-item .nm-button-overlay > a,
	.nm-state-is-responsive .nm-2col .nm-mediagallery-teaser .nm-mediagallery-item .nm-button-play a,
	.nm-state-is-responsive .nm-mediagallery-teaser .nm-mediagallery-item .nm-button-overlay > a {
		box-shadow: 0 0 0 5px rgba(255, 255, 255, .1);
		height: 35px;
		margin-left: -17px;
		margin-top: -17px;
		width: 35px;
	}

	.nm-state-is-responsive .nm-2col .nm-mediagallery-teaser .nm-mediagallery-item .nm-button-overlay > a,
	.nm-state-is-responsive .nm-2col .nm-mediagallery-teaser .nm-mediagallery-item .nm-button-play a span,
	.nm-state-is-responsive .nm-mediagallery-teaser .nm-mediagallery-item .nm-button-overlay > a span {
		background-position: 0 center;
		background-size: 98%;
	}

	/* middle sized play button */
	.nm-state-is-responsive .nm-mediagallery-teaser .nm-md-mediagallery-list-items-3 .nm-mediagallery-item .nm-button-overlay > a,
	.nm-state-is-responsive .nm-mediagallery-teaser .nm-md-mediagallery-list-items-5 .nm-mediagallery-item .nm-button-overlay > a {
		height: 50px;
		width: 50px;
	}
}
