.nm-content-paragraph {
	margin: 0 0 var(--space-xxxl) 0;
	width: 100%;
}

[page-type="widescreen"] .nm-content-paragraph {
	margin: 0 auto var(--space-xxxl);
	width: calc(100% - 2 * var(--space-column));
}

[page-type="widescreen"] .nm-3col .nm-content-paragraph {
	width: calc(100% - 2 * var(--space-side-margin));
}

.nm-content-paragraph__image-container {
	margin: 0 0 var(--space-s);
	width: 100%;
}

.nm-content-paragraph__image {
	width: 100%;
}

.nm-content-paragraph__text-container {
	margin: 0;
	width: 100%;
}

.nm-content-paragraph__headline {
	display: block;
	font-weight: var(--font-weight-bold);
	margin: 0 0 var(--space-m) 0;
}

.nm-content-paragraph__text {
	width: 100%;
}

.nm-content-paragraph__link {
	margin: var(--space-m) 0 0 0;
}

@media all and (min-width: 768px) {
	.nm-content-paragraph {
		align-items: center;
		display: flex;
		flex-direction: row;
	}

	.nm-content-paragraph__image-container + .nm-content-paragraph__text-container {
		width: 60%;
	}

	.nm-content-paragraph--image-right {
		flex-direction: row-reverse;
	}

	.nm-content-paragraph__image-container {
		margin: 0;
		padding: 0 var(--space-xl) 0 0;
		width: calc(40% - var(--space-xl));
	}

	.nm-content-paragraph--image-right .nm-content-paragraph__image-container {
		padding: 0 0 0 var(--space-xl);
	}
}

@media all and (min-width: 1100px) {
	.nm-2col .nm-content-paragraph,
	.nm-1col .nm-content-paragraph {
		display: block;
	}

	.nm-2col .nm-content-paragraph__image-container + .nm-content-paragraph__text-container,
	.nm-1col .nm-content-paragraph__image-container + .nm-content-paragraph__text-container {
		width: 100%;
	}

	.nm-2col .nm-content-paragraph__image-container,
	.nm-1col .nm-content-paragraph__image-container,
	.nm-2col .nm-content-paragraph--image-right .nm-content-paragraph__image-container,
	.nm-1col .nm-content-paragraph--image-right .nm-content-paragraph__image-container {
		margin: 0 0 var(--space-s);
		padding: 0;
		width: 100%;
	}
}

.nm-2col .nm-content-paragraph,
.nm-1col .nm-content-paragraph,
[page-type="widescreen"] .nm-2col .nm-content-paragraph,
[page-type="widescreen"] .nm-1col .nm-content-paragraph,
[page-type="widescreen"] .nm-layer .nm-content-paragraph,
[page-type="widescreen"] .modal-layer .nm-content-paragraph {
	margin: 0 0 var(--space-xxxl) 0;
	width: 100%;
}
