/*  module - teaser
============================================================================= */

/* basic teaser */
.nm-3col .nm-teaser-container,
.nm-2col .nm-teaser-container {
	margin: 0 0 0 -6.9%;
	width: 114.6%;
}

.cq-wcm-edit .nm-teaser-container {
	background: rgba(50, 50, 50, .2);
	padding-top: 20px;
}

.nm-3col .nm-teaser,
.nm-2col .nm-teaser {
	display: inline-block;
	margin: 0 6% 80px;
	vertical-align: top;
	width: 37.4%;
}

/* standard teaser */
.nm-md-standard-teaser strong {
	margin-bottom: 0;
}

.nm-md-standard-teaser .nm-md-standard-teaser-btn {
	margin-top: 13px;
}

.nm-teaser-basic {
	position: relative;
}

.nm-teaser-basic img {
	padding: 3px 0 14px;
	width: 100%;
}

/* basic teaser - paragraph in half */
.nm-teaser-basic-half {
	min-height: 230px;
	position: relative;
	z-index: 0; /* without a z-index images don't show up in layers */
}

.nm-teaser-basic-half p {
	width: 50%;
}

.nm-teaser-basic-half img {
	padding: 0;
	position: absolute;
	right: 0;
	top: 25px;
	width: 100%;
	z-index: -1;
}

.nm-teaser-basic-half .nm-el-hdl {
	margin-bottom: 30px;
}

/* video preview */
.nm-goto-video-link {
	margin-bottom: 13px;
}

.nm-wrap-video-image {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	display: table;
	height: 180px;
	max-height: 180px;
	max-width: 320px;
	width: 100%;
}

/* basic teaser fix */
.nm-fix,
.nm-teaser-video {
	min-height: 230px; /* TODO: teaser-video weg. check */
}

.nm-fix {
	height: 255px;
	position: relative;
}

.nm-teaser-basic.nm-fix img {
	position: relative;
}

.nm-teaser-basic .nm-button-overlay {
	display: table-cell;
	vertical-align: middle;
}

/* standard teaser wide */
.nm-md-standard-teaser-wide-last strong {
	margin-bottom: 10px;
}

.nm-md-standard-teaser-wide-last p {
	margin-bottom: 11px;
}

.nm-md-standard-teaser-wide-last p + p {
	margin-top: 13px;
}

.nm-teaser-basic-wide .nm-teaser-basic {
	float: left;
	width: 26%;
}

.nm-teaser-basic-wide img {
	float: right;
	width: 65%;
}

@media all and (max-width: 479px) {
	.nm-state-is-responsive .nm-3col .nm-teaser,
	.nm-state-is-responsive .nm-2col .nm-teaser {
		margin: 0 0 80px;
		width: 100%;
	}

	.nm-state-is-responsive .nm-3col .nm-teaser-container,
	.nm-state-is-responsive .nm-2col .nm-teaser-container {
		margin: 0;
		width: 100%;
	}
}

/* teaser-main
============================================================================= */

.nm-md-teaser-main h2 {
	margin-bottom: 7px;
}

.nm-md-teaser-main p {
	margin-bottom: 8px;
}
