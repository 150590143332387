/*******************************************
		CSS: Interactive Image Slider
*******************************************/

.nm-interactive-image-slider {
	display: block;
	padding: 0px 4%;
}

.nm-interactive-image-slider__main-headline {
	font-weight: var(--font-weight-bold); /* 400 */
}

.nm-interactive-image-slider__content-container {
	display: flex;
	flex-direction: column-reverse;
	width: 100%;
}

.nm-interactive-image-slider__text-container {
	margin-top: 0px;
	width: 100%;
}

.nm-interactive-image-slider__sub-headline {
	font-weight: var(--font-weight-bold); /* 400 */
	margin-bottom: var(--space-l);
	margin-top: var(--space-xl);
}

.nm-interactive-image-slider__text {
	margin-bottom: var(--space-m);
}

.nm-interactive-image-slider__cta {
	margin-bottom: var(--space-xxl);
}

.nm-interactive-image-slider__image-container {
	height: auto;
	height: 100%;
	margin-top: var(--space-xl);
	position: relative;
	width: 100%;
}

.nm-interactive-image-slider__image {
	width: 100%;
}

.nm-interactive-image-slider__first-image .nm-interactive-image-slider__image {
	height: auto;
	visibility: hidden;
}

.nm-interactive-image-slider__second-image .nm-interactive-image-slider__image {
	height: 100%;
	left: auto;
	position: absolute;
	right: 0;
	width: auto;
}

.nm-interactive-image-slider__first-image {
	background-position: 0 0;
	background-size: cover;
	display: block;
	left: 0%;
	position: relative;
	z-index: 1;
}

.nm-interactive-image-slider__second-image {
	background-position: 100% 0;
	background-size: cover;
	display: block;
	height: 100%;
	left: auto;
	overflow: hidden;
	position: absolute;
	right: 0%;
	top: 0;
	width: 100%;
	z-index: 2;
}

.nm-interactive-image-slider__image-container .nm-interactive-image-slider__control {
	background-color: var(--color-grey-20);
	cursor: move;
	display: block;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 2px;
	z-index: 3;
}

.nm-interactive-image-slider__image-container .nm-interactive-image-slider__control {
	left: calc(40% - 1px);
}

.nm-interactive-image-slider__second-image {
	width: 60%;
}

.nm-interactive-image-slider .nm-interactive-image-slider__control .nm-interactive-image-slider__control-icon {
	bottom: var(--space-s);
	display: block;
	height: 24px;
	left: calc(50% - 12px);
	position: absolute;
	width: 24px;
}

.nm-interactive-image-slider .nm-interactive-image-slider__control .nm-icon-horizontal-slider {
	height: 100%;
	width: 100%;
}

@media screen and (min-width: 768px) {
	.nm-interactive-image-slider__content-container {
		flex-direction: row;
	}

	.nm-1col .nm-interactive-image-slider__content-container {
		flex-direction: column-reverse;
		width: 100%;
	}

	.nm-interactive-image-slider__text-container + .nm-interactive-image-slider__image-container {
		width: 60%;
	}

	.nm-interactive-image-slider__text-container {
		padding-right: var(--space-l);
		width: 40%;
	}

	.nm-1col .nm-interactive-image-slider__text-container {
		width: 100%;
	}

	.nm-interactive-image-slider__content-container--text-right {
		flex-direction: row-reverse;
	}

	.nm-1col .nm-interactive-image-slider__content-container--text-right {
		flex-direction: column-reverse;
	}

	.nm-interactive-image-slider__content-container--text-right .nm-interactive-image-slider__text-container {
		flex-direction: row-reverse;
		padding-left: var(--space-l);
		padding-right: 0px;
		width: 40%;
	}

	.nm-1col .nm-interactive-image-slider__content-container--text-right .nm-interactive-image-slider__text-container {
		padding-left: 0px;
		width: 100%;
	}

	.nm-1col .nm-interactive-image-slider__text-container {
		padding-right: 0px;
		width: 100%;
	}

	.nm-interactive-image-slider__image-container {
		height: 100%;
	}

	.nm-1col .nm-interactive-image-slider__image-container {
		width: 100%;
	}
}
