/*	module - interactive image
============================================================================= */

/* interactive image - general part: used for all: switcher and slider; */

.nm-md-interactive-image h2 {
	margin-bottom: 30px;
}

.nm-md-interactive-image .annot-container {
	float: left;
	margin-top: -8px;
	padding: 0 5% 0 0;
	width: 30%;
}

.nm-md-interactive-image .annot-container.rightAligned {
	padding: 0 0 0 5%;
}

.nm-md-interactive-image .annot-container strong {
	margin-bottom: 17px;
}

.nm-md-interactive-image .annot-container .nm-el-lk {
	margin-top: 10px;
}

.nm-md-interactive-image .annot-container .nm-el-btn {
	margin-top: 13px;
}

.nm-md-interactive-image .nm-image-container {
	overflow: hidden;
	position: relative;
	text-align: center;
	width: 100%;
}

.nm-md-interactive-image .nm-image-container.annotated {
	float: left;
	width: 65%;
}

.nm-md-interactive-image .nm-image-container.annotated.rightAligned {
	float: right;
}

@media all and (max-width: 749px) {
	.nm-state-is-responsive .nm-md-interactive-image .annot-container {
		float: none;
		margin-top: 25px;
		padding: 0;
		width: 100%;
	}

	.nm-state-is-responsive .nm-md-interactive-image .nm-image-container.annotated {
		float: none;
		width: 100%;
	}

	.nm-state-is-responsive .nm-md-interactive-image .nm-image-container.annotated.rightAligned {
		float: none;
	}
}

/* TODO: The following classes should be namespaced, check the breakpoint, rewrite camel case */
@media all and (max-width: 1177.6px) {
	.nm-state-is-responsive .nm-2col .nm-md-interactive-image .annot-container {
		float: none;
		margin-top: 25px;
		padding: 0;
		width: 100%;
	}

	.nm-state-is-responsive .nm-2col .nm-md-interactive-image .nm-image-container.annotated {
		float: none;
		width: 100%;
	}

	.nm-state-is-responsive .nm-2col .nm-md-interactive-image .nm-image-container.annotated.rightAligned {
		float: none;
	}
}

.nm-state-is-responsive .nm-1col .nm-md-interactive-image .annot-container {
	float: none;
	margin-top: 25px;
	padding: 0;
	width: 100%;
}

.nm-state-is-responsive .nm-1col .nm-md-interactive-image .nm-image-container.annotated {
	float: none;
	width: 100%;
}

.nm-state-is-responsive .nm-1col .nm-md-interactive-image .nm-image-container.annotated.rightAligned {
	float: none;
}

/* interactive image - custom part for slider */
.nm-md-interactive-image .nm-slider-first-image,
.nm-md-interactive-image .nm-slider-second-image {
	background-size: cover;
	cursor: pointer;
	display: block;
}

.nm-md-interactive-image .nm-slider-second-image {
	height: 100%;
	user-select: none;
	width: 100%;
}

.nm-md-interactive-image .nm-slider-first-image {
	background-position: top left;
	position: relative;
	z-index: 1;
}

.nm-md-interactive-image .nm-slider-first-image img {
	visibility: hidden;
}

.nm-md-interactive-image .nm-slider-second-image {
	background-position: top right;
	overflow: hidden;
	position: absolute;
	right: 0;
	top: 0;
	width: 50%;
	z-index: 2;
}

.nm-md-interactive-image .nm-slider-second-image img {
	height: 100%;
	position: absolute;
	right: 0;
	width: auto;
}

.nm-md-interactive-image .nm-slider-first-image::selection,
.nm-md-interactive-image .nm-slider-first-image img::selection,
.nm-md-interactive-image .nm-slider-second-image::selection,
.nm-md-interactive-image .nm-slider-second-image img::selection {
	background: transparent;
}

.nm-md-interactive-image .nm-image-container.slider img {
	max-width: 100%;
}

.nm-md-interactive-image .nm-slider-control {
	background-color: #6d7579;
	cursor: move;
	display: block;
	height: 100%;
	left: 50%;
	position: absolute;
	top: 0;
	width: 1px;
	z-index: 2;
}

.nm-md-interactive-image .nm-slider-control-items {
	box-shadow: 0 0 0 5px rgba(255, 255, 255, .1);
	display: block;
	height: 30px;
	left: -30px;
	position: absolute;
	top: -webkit-calc(50% - 15px);
	top: calc(50% - 15px);
	width: 60px;
}

.nm-md-interactive-image .nm-slider-control a {
	display: block;
	height: 30px;
	position: absolute;
	width: 30px;
}

.nm-md-interactive-image .nm-slider-control .nm-backarrow {
	background: url("../assets/img/sprite-main.png") no-repeat -10px -266px #000;
	left: 0;
}

.nm-md-interactive-image .nm-slider-control .nm-nextarrow {
	background: url("../assets/img/sprite-main.png") no-repeat 13px -266px #000;
	right: 0;
}
