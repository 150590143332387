/* mobile-first */

.nm-md-inline-mediagallery {
	position: relative;
}

.nm-md-inline-mediagallery input {
	display: none;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-fullscreen {
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	height: 100%;
	width: 100%;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-fullscreen-close-btn {
	background-color: #000;
	border-radius: 45px;
	cursor: pointer;
	display: none;
	height: 45px;
	position: absolute;
	right: 12px;
	top: 12px;
	width: 45px;
	z-index: 2;
}

.nm-md-inline-mediagallery.nm-md-mediagallery-fullscreen-mode .nm-inline-mediagallery-fullscreen-close-btn {
	display: inline-block;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-fullscreen-close-btn .nm-icon-cross-large {
	display: inline-block;
	fill: #fff;
	height: 45px;
	width: 45px;
}

.nm-md-inline-mediagallery .nm-headline {
	margin-bottom: 18px;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-download-all {
	display: none;
	height: 32px;
	position: absolute;
	right: 0;
	top: 0;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-download-all .nm-icon-save {
	display: inline-block;
}

.nm-md-inline-mediagallery .nm-icon-save {
	height: 32px;
	width: 32px;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-download-all span {
	display: inline-block;
	vertical-align: super;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-toggle-detail {
	display: none;
}

.nm-inline-mediagallery__asset-disclaimer-inline {
	position: relative;
	top: -3px;
	z-index: 2;
}

.nm-inline-mediagallery__asset-disclaimer-overlay {
	display: none;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-big-image-wrap .nm-inline-mediagallery-toggle-detail-label {
	background-color: #000;
	border-radius: 45px;
	bottom: 12px;
	cursor: pointer;
	display: inline-block;
	height: 45px;
	position: absolute;
	right: 12px;
	width: 45px;
	z-index: 3;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-big-image-wrap .nm-inline-mediagallery-toggle-detail-label .nm-icon-cross-large {
	fill: #fff;
	height: 48px;
	left: -1px;
	position: absolute;
	top: -1px;
	transform: rotate(45deg);
	transition-duration: .3s;
	transition-property: transform;
	width: 48px;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-image-container.nm-inline-mediagallery-video-playing ~ .nm-inline-mediagallery-toggle-detail-label {
	display: none;
}

.nm-md-inline-mediagallery.nm-md-mediagallery-fullscreen-mode .nm-inline-mediagallery-toggle-detail-label {
	display: none;
}

.nm-inline-mediagallery-container,
.nm-inline-mediagallery-wrap {
	height: auto;
	margin-left: -4.35%; /* negative margins to set of 92% width from .nm-content parent */
	margin-right: -4.35%;
	position: relative;
}

.nm-inline-mediagallery-slider-list {
	margin-left: 4%;
}

/* reset negative margins for new HP/MSP where .nm-content is 100% */
[page-type="widescreen"] .nm-inline-mediagallery-container,
[page-type="widescreen"] .nm-inline-mediagallery-wrap {
	margin-left: 0;
	margin-right: 0;
}

[page-type="widescreen"] .nm-inline-mediagallery-slider-list {
	margin-left: 0;
}

[page-type="widescreen"] .nm-md-inline-mediagallery .nm-headline {
	padding: 0 4%;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-big-image-wrap {
	height: 0;
	margin-bottom: 12px;
	overflow: hidden;
	padding-bottom: 56.25%;
	position: relative;
	width: 100%;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-image-container {
	display: flex;
	position: relative;
	width: 200%;
	z-index: 1;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-animate-prev .nm-inline-mediagallery-image-container,
.nm-md-inline-mediagallery .nm-inline-mediagallery-animate-next .nm-inline-mediagallery-image-container {
	transition: margin-left .3s;
}

.nm-inline-mediagallery-slider-list-wrap {
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: none;
	overflow-x: scroll;
	overflow-y: hidden;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-slider-list.nm-transform-animation {
	transition: transform .3s;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-image-wrap {
	cursor: url("data:image/png;base64,AAACAAEAICACAAcABQAwAQAAFgAAACgAAAAgAAAAQAAAAAEAAQAAAAAAAAEAAAAAAAAAAAAAAgAAAAAAAAAAAAAA////AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD8AAAA/AAAAfwAAAP+AAAH/gAAB/8AAA//AAAd/wAAGf+AAAH9gAADbYAAA2yAAAZsAAAGbAAAAGAAAAAAAAA//////////////////////////////////////////////////////////////////////////////////////gH///4B///8Af//+AD///AA///wAH//4AB//8AAf//AAD//5AA///gAP//4AD//8AF///AB///5A////5///8="), auto;
	width: 50%;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-image {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	display: block;
	height: 0;
	overflow: hidden;
	padding-bottom: 56.25%;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-image.nm-inline-mediagallery-video {
	background-size: contain;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-image .nm-video {
	display: none;
}

.nm-inline-mediagallery-navigation {
	bottom: calc(50% - 22px);
	display: none;
	height: 45px;
	overflow: hidden;
	position: absolute;
	width: 100%;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-prev,
.nm-md-inline-mediagallery .nm-inline-mediagallery-next {
	cursor: pointer;
	display: inline-block;
	height: 45px;
	left: -6px;
	position: relative;
	width: 45px;
	z-index: 2;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-prev .nm-icon-back-large,
.nm-md-inline-mediagallery .nm-inline-mediagallery-next .nm-icon-forward-large {
	height: 45px;
	width: 45px;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-navigation-gradients .nm-inline-mediagallery-navigation-gradients-left,
.nm-md-inline-mediagallery .nm-inline-mediagallery-navigation-gradients .nm-inline-mediagallery-navigation-gradients-right {
	height: 100%;
	max-height: 100%;
	position: absolute;
	top: 0;
	transition: max-height .2s;
	width: 16%;
	z-index: 2;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-navigation-gradients .nm-inline-mediagallery-navigation-gradients-left {
	background: -webkit-gradient(linear, right top, left top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, .3)));
	background: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, .3));
	left: 0;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-navigation-gradients .nm-inline-mediagallery-navigation-gradients-right {
	background: -webkit-gradient(linear, right top, left top, from(rgba(255, 255, 255, .3)), to(rgba(255, 255, 255, 0)));
	background: linear-gradient(to left, rgba(255, 255, 255, .3), rgba(255, 255, 255, 0));
	right: 0;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-navigation-gradients {
	pointer-events: none;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-video-hover:hover + .nm-inline-mediagallery-navigation-gradients .nm-inline-mediagallery-navigation-gradients-left,
.nm-md-inline-mediagallery .nm-inline-mediagallery-video-hover:hover + .nm-inline-mediagallery-navigation-gradients .nm-inline-mediagallery-navigation-gradients-right {
	max-height: calc(100% - 80px);
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-video-overlay {
	background-color: rgba(0, 0, 0, .5);
	height: 100%;
	left: 0;
	pointer-events: none;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 2;
}

.nm-md-inline-mediagallery .nm-icon-play-large {
	fill: #fff;
	left: 0;
	margin: 0 auto;
	pointer-events: none;
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	width: 24px;
	z-index: 3;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-animate-prev .nm-inline-mediagallery-image-container + .nm-inline-mediagallery-navigation .nm-inline-mediagallery-prev,
.nm-md-inline-mediagallery .nm-inline-mediagallery-animate-prev .nm-inline-mediagallery-image-container + .nm-inline-mediagallery-navigation .nm-inline-mediagallery-next,
.nm-md-inline-mediagallery .nm-inline-mediagallery-animate-next .nm-inline-mediagallery-image-container + .nm-inline-mediagallery-navigation .nm-inline-mediagallery-prev,
.nm-md-inline-mediagallery .nm-inline-mediagallery-animate-next .nm-inline-mediagallery-image-container + .nm-inline-mediagallery-navigation .nm-inline-mediagallery-next {
	pointer-events: none;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-navigation.nm-inline-mediagallery-arrow-dark svg {
	fill: #fff;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-next {
	float: right;
	left: auto;
	right: -6px;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-animate-next .nm-inline-mediagallery-image-container {
	margin-left: -100%;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-animate-prev .nm-inline-mediagallery-image-container {
	margin-left: 100%;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-animate-prev .nm-inline-mediagallery-image-container .nm-inline-mediagallery-image-wrap:first-child {
	order: 1;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-animate-prev .nm-inline-mediagallery-image-container .nm-inline-mediagallery-image-wrap:last-child {
	margin-left: -50%;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-wrap {
	min-height: 39px;
	overflow: hidden;
	position: relative;
	transition: max-height .6s ease-out 0s;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-slider {
	height: 0;
	left: 0;
	opacity: 1;
	padding-bottom: 14.0625%;
	position: absolute;
	top: 0;
	transition: opacity .3s ease-out 0s;
	width: 100%;
	z-index: 2;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-slider-navigation {
	background-color: #fff;
	bottom: -7px;
	display: none;
	height: 40px;
	position: absolute;
	width: 100%;
	z-index: 100;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-slider-navigation a {
	display: none;
	height: 32px;
	position: absolute;
	top: 0;
	width: 32px;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-slider-navigation .nm-inline-mediagallery-slider-next {
	right: -12px;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-slider-navigation .nm-inline-mediagallery-slider-prev {
	left: -12px;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-slider-list {
	display: flex;
	padding-bottom: 25px;
	position: relative;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-slider .nm-inline-mediagallery-slider-item {
	box-sizing: border-box;
	cursor: pointer;
	margin-left: 6px;
	max-height: 89px;
	max-width: 158px;
	overflow: hidden;
	width: calc(25% - 4.5px);
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-animate-prev .nm-inline-mediagallery-image-container + .nm-inline-mediagallery-wrap .nm-inline-mediagallery-slider .nm-inline-mediagallery-slider-item,
.nm-md-inline-mediagallery .nm-inline-mediagallery-animate-next .nm-inline-mediagallery-image-container + .nm-inline-mediagallery-wrap .nm-inline-mediagallery-slider .nm-inline-mediagallery-slider-item {
	pointer-events: none;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-slider-list li.nm-inline-mediagallery-slider-item:first-child {
	margin-left: 0;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-slider-item-thumbnail {
	box-sizing: border-box;
	display: block;
	height: 0;
	overflow: hidden;
	padding-bottom: 56.25%;
	position: relative;
	width: 100%;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-slider-item-thumbnail .nm-image {
	position: relative;
	z-index: 1;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-slider .nm-inline-mediagallery-slider-item.nm-inline-mediagallery-slider-item-active {
	left: 0;
	margin-left: 0;
	pointer-events: none;
	position: absolute;
	top: 0;
	transition: left .3s;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-slider .nm-inline-mediagallery-slider-item-active .nm-inline-mediagallery-slider-item-thumbnail {
	display: block;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-slider .nm-inline-mediagallery-slider-item-active::before,
.nm-md-inline-mediagallery .nm-inline-mediagallery-slider .nm-inline-mediagallery-slider-item-active::after {
	border: 1px solid #000;
	bottom: 0;
	box-sizing: border-box;
	content: "";
	display: block;
	height: 100%;
	left: 0;
	max-height: 89px;
	max-width: 158px;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 2;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-slider .nm-inline-mediagallery-slider-item-active::before {
	border: 1px solid #fff;
	border-bottom: 3px solid #fff;
	bottom: 1px;
	left: 1px;
	right: 1px;
	top: 1px;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-details {
	opacity: 0;
	position: relative;
	transition: opacity .3s ease-out 0s;
	z-index: 1;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-detail-item {
	display: none;
	margin-left: 12px;
	margin-right: 12px;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-detail-item .nm-inline-mediagallery-detail-head {
	margin-bottom: 12px;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-detail-item .nm-inline-mediagallery-detail-copy {
	margin-bottom: 18px;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-detail-item .nm-inline-mediagallery-detail-socialbar {
	display: flex;
	justify-content: flex-end;
	width: 100%;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-detail-item .nm-inline-mediagallery-detail-socialbar li a {
	cursor: pointer;
	display: block;
	height: 32px;
	width: 32px;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-detail-item .nm-inline-mediagallery-detail-socialbar .nm-icon-save {
	height: 32px;
	width: 32px;
}

.nm-md-inline-mediagallery .nm-inline-mediagallery-slider-list,
.nm-md-inline-mediagallery .nm-inline-mediagallery-slider-list .nm-inline-mediagallery-slider-item-thumbnail {
	cursor: pointer;
}

.nm-md-inline-mediagallery .nm-inline-thumb-play {
	background-color: #000;
	opacity: .6;
}

@media screen and (min-width: 320px) {
	.nm-md-inline-mediagallery .nm-inline-mediagallery-wrap {
		max-height: calc(43px + 32px);
	}

	.nm-md-inline-mediagallery .nm-inline-mediagallery-slider {
		padding-bottom: calc(43px + 32px);
	}
}

@media screen and (min-width: 350px) {
	.nm-md-inline-mediagallery .nm-inline-mediagallery-wrap {
		max-height: calc(50px + 32px);
		min-height: calc(50px + 32px);
	}

	.nm-md-inline-mediagallery .nm-inline-mediagallery-slider {
		padding-bottom: calc(50px + 32px);
	}
}

@media screen and (min-width: 480px) {
	.nm-md-inline-mediagallery .nm-inline-mediagallery-detail-item,
	.nm-md-inline-mediagallery .nm-inline-mediagallery-detail-item {
		margin-left: 0;
		margin-right: 0;
	}
}

@media screen and (min-width: 400px) {
	.nm-md-inline-mediagallery .nm-inline-mediagallery-wrap {
		max-height: calc(56px + 32px);
		min-height: calc(56px + 32px);
	}

	.nm-md-inline-mediagallery .nm-inline-mediagallery-slider {
		padding-bottom: calc(56px + 32px);
	}
}

@media screen and (min-width: 450px) {
	.nm-md-inline-mediagallery .nm-inline-mediagallery-wrap {
		max-height: calc(63px + 32px);
		min-height: calc(63px + 32px);
	}

	.nm-md-inline-mediagallery .nm-inline-mediagallery-slider {
		padding-bottom: calc(63px + 32px);
	}
}

@media screen and (min-width: 500px) {
	.nm-md-inline-mediagallery .nm-inline-mediagallery-wrap {
		max-height: calc(69px + 32px);
		min-height: calc(69px + 32px);
	}

	.nm-md-inline-mediagallery .nm-inline-mediagallery-slider {
		padding-bottom: calc(69px + 32px);
	}
}

@media screen and (min-width: 550px) {
	.nm-md-inline-mediagallery .nm-inline-mediagallery-wrap {
		max-height: calc(76px + 32px);
		min-height: calc(76px + 32px);
	}

	.nm-md-inline-mediagallery .nm-inline-mediagallery-slider {
		padding-bottom: calc(76px + 32px);
	}
}

@media screen and (min-width: 600px) {
	.nm-md-inline-mediagallery .nm-inline-mediagallery-wrap {
		max-height: calc(82px + 32px);
		min-height: calc(82px + 32px);
	}

	.nm-md-inline-mediagallery .nm-inline-mediagallery-slider {
		padding-bottom: calc(82px + 32px);
	}
}

@media screen and (min-width: 650px) {
	.nm-md-inline-mediagallery .nm-inline-mediagallery-wrap {
		max-height: calc(86px + 32px);
		min-height: calc(86px + 32px);
	}

	.nm-md-inline-mediagallery .nm-inline-mediagallery-slider {
		padding-bottom: calc(86px + 32px);
	}
}

/* in 3col maximum of 4 thumbnails with max-width 158px */
@media screen and (min-width: 706px) {
	.nm-md-inline-mediagallery .nm-inline-mediagallery-wrap {
		max-height: calc(90px + 32px);
		min-height: calc(90px + 32px);
	}

	.nm-md-inline-mediagallery .nm-inline-mediagallery-slider {
		padding-bottom: calc(90px + 32px);
	}
}

@media screen and (min-width: 768px) {
	.nm-md-inline-mediagallery .nm-inline-mediagallery-navigation,
	.nm-md-inline-mediagallery .nm-inline-mediagallery-slider-navigation,
	.nm-md-inline-mediagallery .nm-inline-mediagallery-download-all {
		display: block;
	}
}

@media screen and (min-width: 480px) {
	.nm-inline-mediagallery-container,
	.nm-inline-mediagallery-wrap {
		margin-left: 0;
		margin-right: 0;
	}

	.nm-inline-mediagallery-slider-list {
		margin-left: 0;
	}

	[page-type="widescreen"] .nm-md-inline-mediagallery {
		padding: 0 4%;
	}

	[page-type="widescreen"] .nm-md-inline-mediagallery .nm-headline {
		padding: 0;
	}

	[page-type="widescreen"] .nm-md-inline-mediagallery .nm-inline-mediagallery-download-all {
		right: 4%;
	}
}

@media all and (min-width: 1024px) {
	.nm-3col .nm-inline-mediagallery__asset-disclaimer-inline {
		display: none;
	}

	.nm-3col .nm-inline-mediagallery__asset-disclaimer-overlay {
		bottom: var(--space-m);
		color: var(--color-white);
		display: inline-block;
		left: var(--space-m);
		position: absolute;
		text-shadow: 0 0 5px rgba(0, 0, 0, .3);
		transition: bottom .15s ease-in-out;
		z-index: 3;
	}

	.nm-3col .nm-inline-mediagallery-video-hover:hover .nm-inline-mediagallery__asset-disclaimer-overlay {
		display: none;
	}
}

@media screen and (min-width: 1440px) {
	[page-type="widescreen"] .nm-md-inline-mediagallery {
		padding: 0 12%;
	}

	[page-type="widescreen"] .nm-md-inline-mediagallery .nm-inline-mediagallery-download-all {
		right: 12%;
	}
}
