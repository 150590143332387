/* tables (for the generic styles see generic.css */

.nm-text-alpha {
	text-align: left;
}

.nm-text-beta {
	text-align: right;
}

.nm-text-gamma {
	text-align: center;
}

/* Table with 2 columns */
.nm-two-cols .nm-col1 {
	width: 70%;
}

.nm-two-cols .nm-col2 {
	border-right: none;
	width: 30%;
}

/* Table with 3 columns */
.nm-three-cols .nm-col1 {
	width: 40%;
}

.nm-three-cols .nm-col2 {
	width: 30%;
}

.nm-three-cols .nm-col3 {
	border-right: none;
	width: 30%;
}

/* Table with 4 columns */
.nm-four-cols .nm-col1 {
	width: 35.1%;
}

.nm-four-cols .nm-col2 {
	width: 16.05%;
}

.nm-four-cols .nm-col3 {
	width: 25.85%;
}

.nm-four-cols .nm-col4 {
	border-right: none;
	width: 22.7%;
}
