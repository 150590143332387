/*	module - benefits
============================================================================= */

.nm-md-benefits .nm-el-hdl-04 {
	margin-bottom: 11px;
}

.nm-md-benefits .nm-otp-list li {
	margin-bottom: 12px;
}
