/**************************************************************************
	Interactive image: switch
**************************************************************************/

.nm-interactive-image-switch {
	padding-left: 4%;
	padding-right: 4%;
}

.nm-interactive-image-switch__switcher-radio {
	display: none;
}

.nm-interactive-image-switch__main-headline {
	font-weight: var(--font-weight-bold);
	margin-bottom: var(--space-xl);
}

.nm-interactive-image-switch__content-container {
	display: flex;
	flex-direction: column-reverse;
	width: 100%;
}

.nm-interactive-image-switch__sub-headline {
	font-weight: var(--font-weight-bold);
	margin-bottom: var(--space-l);
}

.nm-interactive-image-switch__text {
	margin-bottom: var(--space-m);
	width: 100%;
}

.nm-interactive-image-switch__cta {
	margin-bottom: var(--space-xxl);
}

.nm-interactive-image-switch__image-container-wrapper {
	width: 100%;
}

.nm-interactive-image-switch__image-container {
	height: auto;
	margin: 0;
	width: 100%;
}

.nm-interactive-image-switch__image {
	width: 100%;
}

.nm-interactive-image-switch__image-container .nm-interactive-image-switch__image {
	height: auto;
	width: 100%;
}

.nm-interactive-image-switch__switch-container {
	display: flex;
	justify-content: center;
	width: 100%;
}

.nm-switcher-radio-tab1:checked ~ .nm-interactive-image-switch__image-container .nm-interactive-image-switch__image-tab1,
.nm-switcher-radio-tab2:checked ~ .nm-interactive-image-switch__image-container .nm-interactive-image-switch__image-tab2 {
	height: 100%;
	opacity: 1;
}

.nm-switcher-radio-tab1:checked ~ .nm-interactive-image-switch__image-container .nm-interactive-image-switch__image-tab2,
.nm-switcher-radio-tab2:checked ~ .nm-interactive-image-switch__image-container .nm-interactive-image-switch__image-tab1 {
	height: 0;
	opacity: 0;
}

.nm-interactive-image-switch__switch-container .nm-interactive-image-switch__navigation-tab-label {
	border-bottom: 2px solid #000;
	cursor: default;
	opacity: 1;
}

.nm-switcher-radio-tab1:checked ~ .nm-interactive-image-switch__switch-container .nm-interactive-image-switch__navigation-tab-label--tab2,
.nm-switcher-radio-tab2:checked ~ .nm-interactive-image-switch__switch-container .nm-interactive-image-switch__navigation-tab-label--tab1 {
	border-bottom: 0;
	cursor: pointer;
	opacity: .5;
}

/*** Tabs ***/

.nm-interactive-image-switch__navigation-area {
	overflow: hidden;
	padding-bottom: var(--space-xl);
	padding-top: var(--space-l);
	position: relative;
	width: auto;
}

.nm-interactive-image-switch__navigation {
	overflow: hidden;
}

.nm-interactive-image-switch__navigation-bar {
	display: flex;
	margin: 0;
	overflow: hidden;
	padding: 0;
}

.nm-interactive-image-switch__navigation-tab {
	display: block;
	flex: 0 1 auto;
	list-style-type: none;
	margin-right: var(--space-xl);
	white-space: nowrap;
}

.nm-interactive-image-switch__navigation-tab:last-child {
	margin-right: 0;
}

/*** Tabs end ***/

@media screen and (min-width: 768px) {
	.nm-interactive-image-switch__content-container {
		flex-direction: row;
	}

	.nm-1col .nm-interactive-image-switch__content-container {
		flex-direction: column-reverse;
	}

	.nm-interactive-image-switch__content-container--text-right {
		flex-direction: row-reverse;
	}

	.nm-1col .nm-interactive-image-switch__content-container--text-right {
		flex-direction: column-reverse;
	}

	.nm-interactive-image-switch__text-container {
		flex-direction: row;
		padding-right: var(--space-l);
		width: 40%;
	}

	.nm-1col .nm-interactive-image-switch__text-container {
		margin: 0;
		width: 100%;
	}

	.nm-interactive-image-switch__content-container--text-right .nm-interactive-image-switch__text-container {
		flex-direction: row-reverse;
		padding-left: var(--space-l);
		padding-right: 0;
		width: 40%;
	}

	.nm-1col .nm-interactive-image-switch__content-container--text-right .nm-interactive-image-switch__text-container {
		flex-direction: column;
		margin: 0;
		width: 100%;
	}

	.nm-interactive-image-switch__text-container + .nm-interactive-image-switch__image-container-wrapper {
		width: 60%;
	}

	.nm-1col .nm-interactive-image-switch__text-container + .nm-interactive-image-switch__image-container-wrapper {
		width: 100%;
	}

	.nm-interactive-image-switch__switch-container {
		margin: 0;
	}

	.nm-1col .nm-interactive-image-switch__switch-container {
		margin: 0;
	}
}
