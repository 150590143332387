/*******************************************************************
	stage-headline
	mobile-first
*******************************************************************/

/* scope */
.nm-module-stage-headline {
	background-color: var(--color-white);
}

.nm-module-stage-headline .nm-headline-container {
	margin: 0 auto;
	max-width: 1177.6px;
	overflow: visible;
	padding: 5px 0 0;
	width: 92%;
}

.nm-module-stage-headline .nm-headline {
	font-weight: var(--font-weight-bold);
	margin-bottom: 0;
	position: relative;
}

/* Media Queries */
@media all and (min-width: 1024px) {
	.nm-module-stage-headline {
		background: none;
		height: 0;
		padding: 0;
	}

	.nm-module-stage-headline .nm-headline {
		background-color: #fff;
		box-shadow: 0 0 0 5px rgba(255, 255, 255, .15);
		display: inline-block;
		margin-bottom: -1px;
		padding: 0 5px;
		position: relative;
		top: 21px;
		z-index: 12;
	}
}
