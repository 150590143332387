.nm-module-textblock-large {
	margin: 0 0 var(--space-m);
}

[page-type=widescreen] .nm-module-textblock-large {
	margin: 0 4% var(--space-m);
}

@media all and (min-width: 1440px) {
	[page-type=widescreen] .nm-module-textblock-large {
		margin: 0 calc(100% - 66% - 8% - 4%) var(--space-m) calc(8% + 4%);
	}
}

.nm-layer .nm-module-textblock-large {
	margin-bottom: 23px;
}

.nm-layer .nm-module-textblock-large + .nm-module.nm-md-options-list {
	margin-top: 65px;
}

.nm-2col .nm-module-textblock-large,
.nm-1col .nm-module-textblock-large,
[page-type="widescreen"] .nm-2col .nm-module-textblock-large,
[page-type="widescreen"] .nm-1col .nm-module-textblock-large,
[page-type="widescreen"] .nm-layer .nm-module-textblock-large,
[page-type="widescreen"] .modal-layer .nm-module-textblock-large {
	margin: 0 0 var(--space-m);
}
