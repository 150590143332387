/* table editorial */
.nm-tbl-red {
	margin: 0 0 70px;
	overflow-x: auto;
}

.nm-tbl-red th,
.nm-tbl-red td {
	background: #e7e9ea;
	border-bottom: 2px solid #fff;
	font-family: Verdana, sans-serif;
	font-size: 1.2rem;
	line-height: 1.8rem;
	text-align: left;
	vertical-align: top;
}

.nm-tbl-red th {
	font-weight: 700;
	padding: 10px;
	white-space: pre;
}

.nm-tbl-red tr:first-child th {
	height: 30px;
}

.nm-tbl-red td {
	padding: 10px;
	width: 33.3%;
}

.nm-tbl-red td > img {
	margin-bottom: -10px;
	margin-left: -10px;
	margin-top: -10px;
	/* width: 100%; */
	width: -webkit-calc(100% + 20px);
	width: calc(100% + 20px);
}

.nm-tbl-red-text {
	padding: 10px;
}

.nm-tbl-red li {
	background: 0 6px no-repeat transparent url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NTc3MiwgMjAxNC8wMS8xMy0xOTo0NDowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QTQwNjM1Q0M0MThDMTFFNEIyNDM5QTg1RTQzNDNDNjEiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QTQwNjM1Q0Q0MThDMTFFNEIyNDM5QTg1RTQzNDNDNjEiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpBNDA2MzVDQTQxOEMxMUU0QjI0MzlBODVFNDM0M0M2MSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpBNDA2MzVDQjQxOEMxMUU0QjI0MzlBODVFNDM0M0M2MSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PpdpZfYAAAA9SURBVHjaYmaAAH4gTgFiAyC+C8Q/YYIgzn8ovg/EIiCJHCRBGM5hYsADBLAZxYhkeSwQ/wLilUD8ESDAAC8FEg99M2FqAAAAAElFTkSuQmCC");
	margin-top: 7px;
	padding-left: 13px;
}

.nm-tbl-red li:first-child {
	margin-top: 0;
}

.nm-tbl-red b {
	font-weight: bold;
}

.nm-tbl-red a {
	color: #000;
	cursor: pointer;
	display: inline-block;
	font-weight: 700;
	padding-left: 12px;
	position: relative;
	text-decoration: none;
}

.nm-tbl-red a::before {
	background: no-repeat left center url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAJCAYAAAD+WDajAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTQwMjk3RDBGQUYzMTFFMzlFOURENDUzRTM3MzY3NjEiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTQwMjk3RDFGQUYzMTFFMzlFOURENDUzRTM3MzY3NjEiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2NDZCNTZGNUZBRjMxMUUzOUU5REQ0NTNFMzczNjc2MSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo2NDZCNTZGNkZBRjMxMUUzOUU5REQ0NTNFMzczNjc2MSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PhOroJcAAACGSURBVHjaYjzDYKzAwMBwngECHI3/n7kAZTMwATkPgHQhEAsA8f6zjCYKcEkQAVSwAEhNgCpYD1QAohkY////D1PIABQEGW8AxCCjHZkYUMEBKA0yWgCuE6grAGQkEH+AOYwJKgEyaj5UVyHMxYxAr4BdCbUrEeo4MGCBqQYZh+xHEAAIMACW6i2Fr+XVoQAAAABJRU5ErkJggg==");
	content: "";
	display: inline-block;
	height: 15px;
	left: 0;
	position: absolute;
	top: 0;
	vertical-align: middle;
	width: 7px;
}

.nm-tbl-red a:hover {
	color: var(--color-progressive-red);
}
