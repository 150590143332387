/*	hdgdl - the ultimate highlights module
========================================================================== */

.nm-md-highlight-module {
	min-width: 294px;
}

/* video button - big */
.nm-md-highlight-module-item .nm-button-overlay {
	display: block;
	left: 50%;
	margin: -35px 0 0 -35px; /* width of button (70px)/2 */
	position: absolute;
	top: 50%;
}

/* global rules */
.nm-md-highlight-module-item {
	float: left;
	max-width: 50%;
	min-width: 50%;
	position: relative;
}

.nm-md-highlight-module-item:hover {
	cursor: pointer;
}

.nm-md-highlight-module-item .nm-md-highlight-module-item-span {
	background-repeat: no-repeat;
	display: block;
	position: relative;
	z-index: 0;
}

/* plus button */
.nm-md-highlight-module-item .nm-md-highlight-module-item-span::after {
	background: #000 url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAAhElEQVR4nO2YQQqAMAwEs+L/vxwvegsq3bYudufYggwDgVRkZoYw29cCT1iQRV5wrw4BYLZIREQ1sPIFLchiQRYLsqwlmCc9v7lWwRFYkKXcZt5wNwzVXeuG9N+CVZGrXM99Ur6gBVnkBZuHpGLEY0u+oAVZLMhiQRZ5QfgXMIkFWeQFD/FPIEvfCKErAAAAAElFTkSuQmCC") no-repeat center center;
	box-shadow: 0 0 0 5px rgba(255, 255, 255, .1);
	content: "";
	cursor: pointer;
	display: block;
	height: 30px;
	pointer-events: none;
	position: absolute;
	right: 10px;
	top: 10px;
	width: 30px;
	z-index: 2;
}

.nm-md-highlight-module-item a {
	display: block;
}

.nm-md-highlight-module-item .nm-md-highlight-module-item-square {
	background-repeat: no-repeat;
	background-size: cover;
	padding-bottom: 100%;
	width: 100%;
}

.nm-md-highlight-module-item .nm-md-highlight-module-item-rect {
	background-repeat: no-repeat;
	background-size: cover;
	display: none;
	padding-bottom: 50%;
	width: 100%;
}

.nm-md-highlight-module-item .nm-md-highlight-module-item-description {
	background-color: rgba(0, 0, 0, .8);
	display: block;
	height: 100%;
	left: 0;
	opacity: 0;
	position: absolute;
	top: 0;
	transition: opacity 250ms;
	width: 100%;
}

.nm-md-highlight-module-item:hover .nm-md-highlight-module-item-description {
	opacity: 1;
	z-index: 0;
}

.nm-md-highlight-module-item .nm-md-highlight-module-item-description span {
	box-sizing: border-box;
	display: block;
	margin: 0;
	padding: 0 20px;
	position: relative;
	text-align: center;
	top: 50%;
	transform: translate(0, -50%);
	width: 100%;
}

/* 4 items */
.nm-md-highlight-module-list-items-4 .nm-md-highlight-module-item-2 {
	min-width: 50%;
}

.nm-md-highlight-module-list-items-4 .nm-md-highlight-module-item-3,
.nm-md-highlight-module-list-items-4 .nm-md-highlight-module-item-4 {
	min-width: 25%;
}

.nm-md-highlight-module-list-items-4 .nm-md-highlight-module-item-2 .nm-md-highlight-module-item-square {
	display: none;
}

.nm-md-highlight-module-list-items-4 .nm-md-highlight-module-item-2 .nm-md-highlight-module-item-rect {
	display: block;
}

/* 5 items */
.nm-md-highlight-module-list-items-5 .nm-md-highlight-module-item-1,
.nm-md-highlight-module-list-items-5 .nm-md-highlight-module-item-2,
.nm-md-highlight-module-list-items-5 .nm-md-highlight-module-item-5 {
	min-width: 40%;
}

.nm-md-highlight-module-list-items-5 .nm-md-highlight-module-item-3,
.nm-md-highlight-module-list-items-5 .nm-md-highlight-module-item-4 {
	min-width: 20%;
}

.nm-md-highlight-module-list-items-5 .nm-md-highlight-module-item-2 .nm-md-highlight-module-item-square,
.nm-md-highlight-module-list-items-5 .nm-md-highlight-module-item-5 .nm-md-highlight-module-item-square {
	display: none;
}

.nm-md-highlight-module-list-items-5 .nm-md-highlight-module-item-2 .nm-md-highlight-module-item-rect,
.nm-md-highlight-module-list-items-5 .nm-md-highlight-module-item-5 .nm-md-highlight-module-item-rect {
	display: block;
}

/* 6 items */
.nm-md-highlight-module-list-items-6 .nm-md-highlight-module-item-1,
.nm-md-highlight-module-list-items-6 .nm-md-highlight-module-item-2 {
	min-width: 40%;
}

.nm-md-highlight-module-list-items-6 .nm-md-highlight-module-item-3,
.nm-md-highlight-module-list-items-6 .nm-md-highlight-module-item-4,
.nm-md-highlight-module-list-items-6 .nm-md-highlight-module-item-5,
.nm-md-highlight-module-list-items-6 .nm-md-highlight-module-item-6 {
	min-width: 20%;
}

.nm-md-highlight-module-list-items-6 .nm-md-highlight-module-item-2 .nm-md-highlight-module-item-square {
	display: none;
}

.nm-md-highlight-module-list-items-6 .nm-md-highlight-module-item-2 .nm-md-highlight-module-item-rect {
	display: block;
}

/* 7 items */
.nm-md-highlight-module-list-items-7 .nm-md-highlight-module-item-1 {
	min-width: 40%;
}

.nm-md-highlight-module-list-items-7 .nm-md-highlight-module-item-2,
.nm-md-highlight-module-list-items-7 .nm-md-highlight-module-item-3,
.nm-md-highlight-module-list-items-7 .nm-md-highlight-module-item-4,
.nm-md-highlight-module-list-items-7 .nm-md-highlight-module-item-5,
.nm-md-highlight-module-list-items-7 .nm-md-highlight-module-item-6,
.nm-md-highlight-module-list-items-7 .nm-md-highlight-module-item-7 {
	min-width: 20%;
}

/* 8 items */
.nm-md-highlight-module-list-items-8 .nm-md-highlight-module-item-1,
.nm-md-highlight-module-list-items-8 .nm-md-highlight-module-item-5 {
	min-width: 40%;
}

.nm-md-highlight-module-list-items-8 .nm-md-highlight-module-item-2,
.nm-md-highlight-module-list-items-8 .nm-md-highlight-module-item-3,
.nm-md-highlight-module-list-items-8 .nm-md-highlight-module-item-4,
.nm-md-highlight-module-list-items-8 .nm-md-highlight-module-item-6,
.nm-md-highlight-module-list-items-8 .nm-md-highlight-module-item-7,
.nm-md-highlight-module-list-items-8 .nm-md-highlight-module-item-8 {
	min-width: 20%;
}

.nm-md-highlight-module-list-items-8 .nm-md-highlight-module-item-1 .nm-md-highlight-module-item-square,
.nm-md-highlight-module-list-items-8 .nm-md-highlight-module-item-5 .nm-md-highlight-module-item-square {
	display: none;
}

.nm-md-highlight-module-list-items-8 .nm-md-highlight-module-item-1 .nm-md-highlight-module-item-rect,
.nm-md-highlight-module-list-items-8 .nm-md-highlight-module-item-5 .nm-md-highlight-module-item-rect {
	display: block;
}

@media screen and (max-width: 1036px) {
	/* video button medium */
	.nm-state-is-responsive .nm-md-highlight-module-item .nm-button-overlay {
		margin: -25px 0 0 -25px; /* width of button (50px)/2 */
	}

	.nm-state-is-responsive .nm-md-highlight-module-item .nm-button-play a {
		height: 50px;
		width: 50px;
	}

	.nm-state-is-responsive .nm-md-highlight-module-item .nm-button-play a span {
		background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAA1UlEQVR4nO3awQ2AMAxDUQex/8plAa6xLdf/yImnQisR5pxzcFGP+gbYFZzedeD37+LMDPtGNvrbkK9b4YLTKzi9gtMrOL2C0yt4K5cvK9QVdkDTH2k1WvIOK9GyTUuFlu7SCrT8WGKj5WCAi7YAAzy0DRjgoK3AwD7aDgzsoi3Bm4MAO/D21MMKzBjx2IBZ8ywLMHN4JwezJ5VSsGIsKwOrZtASsHLgTger/y6ggtVYgAh2wAIGxxK7gtMrOL2C0ys4vYLTKzi9Uf+CwO66FS44vevAH9SxPHG4/4/yAAAAAElFTkSuQmCC") no-repeat center center;
		margin: 0 auto 0 35%;
		width: 16px;
	}

	/* 5 items */
	.nm-state-is-responsive .nm-md-highlight-module-list-items-5 .nm-md-highlight-module-item-1 {
		min-width: 50%;
	}

	.nm-state-is-responsive .nm-md-highlight-module-list-items-5 .nm-md-highlight-module-item-2,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-5 .nm-md-highlight-module-item-3,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-5 .nm-md-highlight-module-item-4,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-5 .nm-md-highlight-module-item-5 {
		min-width: 25%;
	}

	.nm-state-is-responsive .nm-md-highlight-module-list-items-5 .nm-md-highlight-module-item-2 .nm-md-highlight-module-item-square,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-5 .nm-md-highlight-module-item-5 .nm-md-highlight-module-item-square {
		display: block;
	}

	.nm-state-is-responsive .nm-md-highlight-module-list-items-5 .nm-md-highlight-module-item-2 .nm-md-highlight-module-item-rect,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-5 .nm-md-highlight-module-item-5 .nm-md-highlight-module-item-rect {
		display: none;
	}

	/* 6 items */
	.nm-state-is-responsive .nm-md-highlight-module-list-items-6 .nm-md-highlight-module-item-1,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-6 .nm-md-highlight-module-item-4 {
		min-width: 50%;
	}

	.nm-state-is-responsive .nm-md-highlight-module-list-items-6 .nm-md-highlight-module-item-2,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-6 .nm-md-highlight-module-item-3,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-6 .nm-md-highlight-module-item-5,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-6 .nm-md-highlight-module-item-6 {
		min-width: 25%;
	}

	.nm-state-is-responsive .nm-md-highlight-module-list-items-6 .nm-md-highlight-module-item-1 .nm-md-highlight-module-item-square,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-6 .nm-md-highlight-module-item-2 .nm-md-highlight-module-item-rect,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-6 .nm-md-highlight-module-item-4 .nm-md-highlight-module-item-square {
		display: none;
	}

	.nm-state-is-responsive .nm-md-highlight-module-list-items-6 .nm-md-highlight-module-item-1 .nm-md-highlight-module-item-rect,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-6 .nm-md-highlight-module-item-2 .nm-md-highlight-module-item-square,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-6 .nm-md-highlight-module-item-4 .nm-md-highlight-module-item-rect {
		display: block;
	}

	/* 7 items */
	.nm-state-is-responsive .nm-md-highlight-module-list-items-7 .nm-md-highlight-module-item-1 {
		min-width: 50%;
	}

	.nm-state-is-responsive .nm-md-highlight-module-list-items-7 .nm-md-highlight-module-item-2,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-7 .nm-md-highlight-module-item-3,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-7 .nm-md-highlight-module-item-4,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-7 .nm-md-highlight-module-item-5,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-7 .nm-md-highlight-module-item-6,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-7 .nm-md-highlight-module-item-7 {
		min-width: 25%;
	}

	.nm-state-is-responsive .nm-md-highlight-module-list-items-7 .nm-md-highlight-module-item-1 .nm-md-highlight-module-item-square {
		display: none;
	}

	.nm-state-is-responsive .nm-md-highlight-module-list-items-7 .nm-md-highlight-module-item-1 .nm-md-highlight-module-item-rect {
		display: block;
	}

	/* 8 items */
	.nm-state-is-responsive .nm-md-highlight-module-list-items-8 .nm-md-highlight-module-item-1,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-8 .nm-md-highlight-module-item-2,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-8 .nm-md-highlight-module-item-3,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-8 .nm-md-highlight-module-item-4,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-8 .nm-md-highlight-module-item-5,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-8 .nm-md-highlight-module-item-6,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-8 .nm-md-highlight-module-item-7,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-8 .nm-md-highlight-module-item-8 {
		min-width: 25%;
	}

	.nm-state-is-responsive .nm-md-highlight-module-list-items-8 .nm-md-highlight-module-item-1 .nm-md-highlight-module-item-square,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-8 .nm-md-highlight-module-item-5 .nm-md-highlight-module-item-square {
		display: block;
	}

	.nm-state-is-responsive .nm-md-highlight-module-list-items-8 .nm-md-highlight-module-item-1 .nm-md-highlight-module-item-rect,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-8 .nm-md-highlight-module-item-5 .nm-md-highlight-module-item-rect {
		display: none;
	}
}

@media screen and (max-width: 749px) {
	/* video button small */
	.nm-state-is-responsive .nm-md-highlight-module-item .nm-button-overlay {
		margin: -17px 0 0 -17px; /* width of button (35px)/2 */
	}

	.nm-state-is-responsive .nm-md-highlight-module-item .nm-button-play a {
		height: 35px;
		width: 35px;
	}

	.nm-state-is-responsive .nm-md-highlight-module-item .nm-button-play a span {
		background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC0AAAAtCAYAAAA6GuKaAAAAsUlEQVR4nO3ZMQ6AMAxDUYK4/5XDBcpC7V9Fskemp6okJFR39zUs92nAnwRNZST6WT2sqqIhX1kVipEnHTSVoKkETX3HyE+agFuuhxtuu9NOuPVFdMHt1cMBR0qeGo7VaSUcbS4qON4RFfAjbXwXfgS9OxnhaMUoh6JVsyeGVg7LCFo93dvRjnWEFe3an9jQzoWPBe3eUMnRxEpNiqZ2gFkhUAmaStBUKv8RoQRNZST6BVhHOFOlXZzbAAAAAElFTkSuQmCC") no-repeat center center;
		margin: 0 auto 0 24%;
	}
}

@media screen and (max-width: 613px) {
	/* 4 items */
	.nm-state-is-responsive .nm-md-highlight-module-list-items-4 .nm-md-highlight-module-item-1 {
		min-width: 100%;
	}

	.nm-state-is-responsive .nm-md-highlight-module-list-items-4 .nm-md-highlight-module-item-2,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-4 .nm-md-highlight-module-item-3 {
		min-width: 33.33%;
	}

	.nm-state-is-responsive .nm-md-highlight-module-list-items-4 .nm-md-highlight-module-item-4 {
		min-width: 33.34%;
	}

	.nm-state-is-responsive .nm-md-highlight-module-list-items-4 .nm-md-highlight-module-item-1 .nm-md-highlight-module-item-square,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-4 .nm-md-highlight-module-item-2 .nm-md-highlight-module-item-rect {
		display: none;
	}

	.nm-state-is-responsive .nm-md-highlight-module-list-items-4 .nm-md-highlight-module-item-1 .nm-md-highlight-module-item-rect,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-4 .nm-md-highlight-module-item-2 .nm-md-highlight-module-item-square {
		display: block;
	}

	/* 5 items */
	.nm-state-is-responsive .nm-md-highlight-module-list-items-5 .nm-md-highlight-module-item-1 {
		min-width: 100%;
	}

	.nm-state-is-responsive .nm-md-highlight-module-list-items-5 .nm-md-highlight-module-item-2,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-5 .nm-md-highlight-module-item-5 {
		min-width: 66.66%;
	}

	.nm-state-is-responsive .nm-md-highlight-module-list-items-5 .nm-md-highlight-module-item-3,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-5 .nm-md-highlight-module-item-4 {
		min-width: 33.34%;
	}

	.nm-state-is-responsive .nm-md-highlight-module-list-items-5 .nm-md-highlight-module-item-1 .nm-md-highlight-module-item-square,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-5 .nm-md-highlight-module-item-2 .nm-md-highlight-module-item-square,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-5 .nm-md-highlight-module-item-5 .nm-md-highlight-module-item-square {
		display: none;
	}

	.nm-state-is-responsive .nm-md-highlight-module-list-items-5 .nm-md-highlight-module-item-1 .nm-md-highlight-module-item-rect,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-5 .nm-md-highlight-module-item-2 .nm-md-highlight-module-item-rect,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-5 .nm-md-highlight-module-item-5 .nm-md-highlight-module-item-rect {
		display: block;
	}

	/* 6 items */
	.nm-state-is-responsive .nm-md-highlight-module-list-items-6 .nm-md-highlight-module-item-1 {
		min-width: 100%;
	}

	.nm-state-is-responsive .nm-md-highlight-module-list-items-6 .nm-md-highlight-module-item-2 {
		min-width: 66.66%;
	}

	.nm-state-is-responsive .nm-md-highlight-module-list-items-6 .nm-md-highlight-module-item-3,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-6 .nm-md-highlight-module-item-6 {
		min-width: 33.34%;
	}

	.nm-state-is-responsive .nm-md-highlight-module-list-items-6 .nm-md-highlight-module-item-4,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-6 .nm-md-highlight-module-item-5 {
		min-width: 33.33%;
	}

	.nm-state-is-responsive .nm-md-highlight-module-list-items-6 .nm-md-highlight-module-item-2 .nm-md-highlight-module-item-square,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-6 .nm-md-highlight-module-item-4 .nm-md-highlight-module-item-rect {
		display: none;
	}

	.nm-state-is-responsive .nm-md-highlight-module-list-items-6 .nm-md-highlight-module-item-2 .nm-md-highlight-module-item-rect,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-6 .nm-md-highlight-module-item-4 .nm-md-highlight-module-item-square {
		display: block;
	}

	/* 7 items */
	.nm-state-is-responsive .nm-md-highlight-module-list-items-7 .nm-md-highlight-module-item-1 {
		min-width: 100%;
	}

	.nm-state-is-responsive .nm-md-highlight-module-list-items-7 .nm-md-highlight-module-item-2,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-7 .nm-md-highlight-module-item-3,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-7 .nm-md-highlight-module-item-5,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-7 .nm-md-highlight-module-item-6 {
		min-width: 33.33%;
	}

	.nm-state-is-responsive .nm-md-highlight-module-list-items-7 .nm-md-highlight-module-item-4,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-7 .nm-md-highlight-module-item-7 {
		min-width: 33.34%;
	}

	/* 8 items */
	.nm-state-is-responsive .nm-md-highlight-module-list-items-8 .nm-md-highlight-module-item-1,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-8 .nm-md-highlight-module-item-2 {
		min-width: 100%;
	}

	.nm-state-is-responsive .nm-md-highlight-module-list-items-8 .nm-md-highlight-module-item-3,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-8 .nm-md-highlight-module-item-4,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-8 .nm-md-highlight-module-item-6,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-8 .nm-md-highlight-module-item-7 {
		min-width: 33.33%;
	}

	.nm-state-is-responsive .nm-md-highlight-module-list-items-8 .nm-md-highlight-module-item-5,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-8 .nm-md-highlight-module-item-8 {
		min-width: 33.34%;
	}

	.nm-state-is-responsive .nm-md-highlight-module-list-items-8 .nm-md-highlight-module-item-1 .nm-md-highlight-module-item-square,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-8 .nm-md-highlight-module-item-2 .nm-md-highlight-module-item-square {
		display: none;
	}

	.nm-state-is-responsive .nm-md-highlight-module-list-items-8 .nm-md-highlight-module-item-1 .nm-md-highlight-module-item-rect,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-8 .nm-md-highlight-module-item-2 .nm-md-highlight-module-item-rect {
		display: block;
	}
}

@media screen and (max-width: 494px) {
	/* 4 items */
	.nm-state-is-responsive .nm-md-highlight-module-list-items-4 .nm-md-highlight-module-item-1,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-4 .nm-md-highlight-module-item-2,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-4 .nm-md-highlight-module-item-3,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-4 .nm-md-highlight-module-item-4 {
		min-width: 50%;
	}

	.nm-state-is-responsive .nm-md-highlight-module-list-items-4 .nm-md-highlight-module-item-1 .nm-md-highlight-module-item-square,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-4 .nm-md-highlight-module-item-2 .nm-md-highlight-module-item-square {
		display: block;
	}

	.nm-state-is-responsive .nm-md-highlight-module-list-items-4 .nm-md-highlight-module-item-1 .nm-md-highlight-module-item-rect,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-4 .nm-md-highlight-module-item-2 .nm-md-highlight-module-item-rect {
		display: none;
	}

	/* 5 items */
	.nm-state-is-responsive .nm-md-highlight-module-list-items-5 .nm-md-highlight-module-item-2,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-5 .nm-md-highlight-module-item-3,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-5 .nm-md-highlight-module-item-4,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-5 .nm-md-highlight-module-item-5 {
		min-width: 50%;
	}

	.nm-state-is-responsive .nm-md-highlight-module-list-items-5 .nm-md-highlight-module-item-2 .nm-md-highlight-module-item-square,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-5 .nm-md-highlight-module-item-5 .nm-md-highlight-module-item-square {
		display: block;
	}

	.nm-state-is-responsive .nm-md-highlight-module-list-items-5 .nm-md-highlight-module-item-2 .nm-md-highlight-module-item-rect,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-5 .nm-md-highlight-module-item-5 .nm-md-highlight-module-item-rect {
		display: none;
	}

	/* 6 items */
	.nm-state-is-responsive .nm-md-highlight-module-list-items-6 .nm-md-highlight-module-item-1,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-6 .nm-md-highlight-module-item-2,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-6 .nm-md-highlight-module-item-3,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-6 .nm-md-highlight-module-item-4,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-6 .nm-md-highlight-module-item-5,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-6 .nm-md-highlight-module-item-6 {
		min-width: 50%;
	}

	.nm-state-is-responsive .nm-md-highlight-module-list-items-6 .nm-md-highlight-module-item-1 .nm-md-highlight-module-item-square,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-6 .nm-md-highlight-module-item-2 .nm-md-highlight-module-item-square {
		display: block;
	}

	.nm-state-is-responsive .nm-md-highlight-module-list-items-6 .nm-md-highlight-module-item-1 .nm-md-highlight-module-item-rect,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-6 .nm-md-highlight-module-item-2 .nm-md-highlight-module-item-rect {
		display: none;
	}

	/* 7 items */
	.nm-state-is-responsive .nm-md-highlight-module-list-items-7 .nm-md-highlight-module-item-1 {
		min-width: 100%;
	}

	.nm-state-is-responsive .nm-md-highlight-module-list-items-7 .nm-md-highlight-module-item-2,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-7 .nm-md-highlight-module-item-3,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-7 .nm-md-highlight-module-item-4,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-7 .nm-md-highlight-module-item-5,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-7 .nm-md-highlight-module-item-6,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-7 .nm-md-highlight-module-item-7 {
		min-width: 50%;
	}

	/* 8 items */
	.nm-state-is-responsive .nm-md-highlight-module-list-items-8 .nm-md-highlight-module-item-1,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-8 .nm-md-highlight-module-item-2,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-8 .nm-md-highlight-module-item-3,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-8 .nm-md-highlight-module-item-4,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-8 .nm-md-highlight-module-item-5,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-8 .nm-md-highlight-module-item-6,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-8 .nm-md-highlight-module-item-7,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-8 .nm-md-highlight-module-item-8 {
		min-width: 50%;
	}

	.nm-state-is-responsive .nm-md-highlight-module-list-items-8 .nm-md-highlight-module-item-1 .nm-md-highlight-module-item-square,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-8 .nm-md-highlight-module-item-2 .nm-md-highlight-module-item-square {
		display: block;
	}

	.nm-state-is-responsive .nm-md-highlight-module-list-items-8 .nm-md-highlight-module-item-1 .nm-md-highlight-module-item-rect,
	.nm-state-is-responsive .nm-md-highlight-module-list-items-8 .nm-md-highlight-module-item-2 .nm-md-highlight-module-item-rect {
		display: none;
	}
}
