/*  module - intro link list - works in all colums
============================================================================= */

.nm-md-intro {
	overflow: hidden;
	position: relative;
}

.nm-3col .nm-md-intro .nm-md-intro-text {
	box-sizing: border-box;
	float: left;
	padding-right: 45px;
	position: relative;
	width: 69.4%;
}

.nm-3col .nm-md-intro .nm-md-intro-text-only {
	width: 83%;
}

.nm-2col .nm-md-intro .nm-md-intro-text {
	float: none;
	width: 100%;
}

.nm-3col .nm-md-intro .nm-hl-pu {
	margin-bottom: 13px;
}

.nm-3col .nm-md-intro .nm-el-hdl-01 span {
	display: block;
}

.nm-2col .nm-md-intro .nm-md-intro-text::after {
	display: none;
}

/* Headline */
.nm-md-intro .nm-md-intro-text .nm-el-hdl-01 {
	margin-bottom: 10px;
}

/* List */
.nm-md-intro .nm-md-intro-link-list ul {
	margin-bottom: -30px;
}

.nm-3col .nm-md-intro .nm-md-intro-link-list {
	box-sizing: border-box;
	float: right;
	margin-top: 7px;
	width: 30.6%;
}

/* overwrites default set of declarations - position of this declaration matters */
.nm-3col .nm-md-intro .nm-md-intro-links-only {
	float: none;
	margin: 0;
	width: 100%;
}

.nm-2col .nm-md-intro .nm-md-intro-link-list {
	float: none;
	margin-top: 41px;
	width: 100%;
}

.nm-3col .nm-md-intro-link-list ul {
	float: right;
	width: 46%;
}

.nm-2col .nm-md-intro-link-list ul {
	float: left;
	max-width: 218px;
}

.nm-2col .nm-md-intro-link-list ul:first-child {
	margin-right: 4%;
}

.nm-3col .nm-md-intro-link-list ul:first-child {
	float: left;
	margin-right: 4%;
}

.nm-md-intro-link-list li {
	display: block;
	margin-bottom: 30px;
}

/*  module - intro link list - breakpoints for responsiveness
============================================================================= */

@media all and (max-width: 1171px) {
	.nm-state-is-responsive .nm-3col .nm-md-intro-link-list ul {
		float: none;
		width: 100%;
	}

	.nm-state-is-responsive .nm-3col .nm-md-intro-link-list ul:first-child {
		margin-bottom: 0;
	}
}

@media all and (max-width: 988px) {
	.nm-state-is-responsive .nm-3col .nm-md-intro .nm-md-intro-text {
		float: none;
		padding-right: 0;
		width: 100%;
	}

	.nm-state-is-responsive .nm-3col .nm-md-intro-link-list ul:first-child,
	.nm-state-is-responsive .nm-3col .nm-md-intro-link-list ul {
		margin-bottom: -30px;
	}

	.nm-state-is-responsive .nm-3col .nm-md-intro::after {
		display: none;
	}

	.nm-state-is-responsive .nm-3col .nm-md-intro .nm-md-intro-link-list {
		float: none;
		margin-top: 41px;
		width: 100%;
	}

	.nm-state-is-responsive .nm-3col .nm-md-intro-link-list ul {
		float: left;
		max-width: 205px;
		width: 46%;
	}
}

@media all and (max-width: 661px) {
	.nm-state-is-responsive .nm-2col .nm-md-intro-link-list ul,
	.nm-state-is-responsive .nm-2col .nm-md-intro-link-list ul:first-child {
		float: none;
		margin: 0 0 -30px;
		max-width: 100%;
		width: 100%;
	}

	.nm-state-is-responsive .nm-2col .nm-md-intro-link-list ul:first-child {
		margin-bottom: 0;
	}
}

@media all and (max-width: 488px) {
	.nm-state-is-responsive .nm-3col .nm-md-intro-link-list ul,
	.nm-state-is-responsive .nm-3col .nm-md-intro-link-list ul:first-child {
		float: none;
		margin: 0;
		max-width: 100%;
		width: 100%;
	}

	.nm-state-is-responsive .nm-3col .nm-md-intro-link-list ul {
		margin-bottom: -30px;
	}
}
