/* text-html
============================================================================= */

.nm-module-text-html {
	margin: 0 0 var(--space-m);
}

[page-type=widescreen] .nm-module-text-html {
	margin: 0 4% var(--space-m);
}

@media all and (min-width: 1440px) {
	[page-type=widescreen] .nm-module-text-html {
		margin: 0 calc(100% - 52% - 8% - 4%) var(--space-m) calc(8% + 4%);
	}
}

.nm-module-text-html p,
.nm-module-text-html ul {
	margin-bottom: var(--space-m);
}

.nm-module-text-html .nm-module-text-html__wltp-disclaimer {
	margin-top: var(--space-m);
}

.nm-module-text-html ul li {
	background: 0 6px no-repeat transparent url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NTc3MiwgMjAxNC8wMS8xMy0xOTo0NDowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QTQwNjM1Q0M0MThDMTFFNEIyNDM5QTg1RTQzNDNDNjEiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QTQwNjM1Q0Q0MThDMTFFNEIyNDM5QTg1RTQzNDNDNjEiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpBNDA2MzVDQTQxOEMxMUU0QjI0MzlBODVFNDM0M0M2MSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpBNDA2MzVDQjQxOEMxMUU0QjI0MzlBODVFNDM0M0M2MSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PpdpZfYAAAA9SURBVHjaYmaAAH4gTgFiAyC+C8Q/YYIgzn8ovg/EIiCJHCRBGM5hYsADBLAZxYhkeSwQ/wLilUD8ESDAAC8FEg99M2FqAAAAAElFTkSuQmCC");
	margin-bottom: 7px;
	padding-left: 13px;
}

.nm-2col .nm-module-text-html,
.nm-1col .nm-module-text-html,
[page-type="widescreen"] .nm-2col .nm-module-text-html,
[page-type="widescreen"] .nm-1col .nm-module-text-html,
[page-type="widescreen"] .nm-layer .nm-module-text-html,
[page-type="widescreen"] .modal-layer .nm-module-text-html {
	margin: 0 0 var(--space-m);
}
