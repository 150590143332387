/*	module - highlights teaser
============================================================================= */

body:not(.cq-wcm-edit) .nm-1col .nm-md-highlight {
	display: none;
}

.nm-md-highlight {
	margin: 0 auto 80px;
	overflow: hidden;
	position: relative;
	width: 82.4%;
}

.nm-md-highlight::after {
	background-color: #d0d3d4;
	content: "";
	height: 100%;
	left: 50%;
	margin-left: -1px;
	position: absolute;
	top: 0;
	visibility: visible;
	width: 1px;
}

.nm-md-highlight-container {
	float: left;
	width: 50%;
}

.nm-md-highlight-container .nm-md-highlight-bl:first-child {
	margin-top: 50px;
}

.nm-md-highlight-container:first-child .nm-md-highlight-bl:first-child {
	margin-top: 0;
}

.nm-md-highlight-container .nm-md-highlight-bl p {
	margin-bottom: 0;
}

.nm-md-highlight-bl {
	float: left;
	margin: 73px 0 0;
	text-align: right;
	width: 100%;
}

.nm-md-highlight-bl-align-right .nm-md-highlight-bl {
	text-align: left;
}

.nm-md-highlight .nm-el-hdl-03 {
	margin-bottom: 16px;
}

.nm-md-highlight .nm-el-lbl-03 {
	color: #fff;
	display: inline-block;
	margin-bottom: 0;
}

.nm-md-highlight .nm-el-lbl-03 > span {
	display: block;
	font-family: AudiTypeExtended, Verdana, sans-serif;
	margin-top: 5px;
}

.nm-md-highlight .nm-el-lbl-03 {
	background-color: var(--color-progressive-red);
	padding: 13px 20px 11px;
}

.nm-md-highlight a:hover .nm-el-lbl-03 {
	background-color: #aa142d;
}

.nm-md-highlight .nm-el-lbl-03.nm-md-highlight-no-subline {
	padding: 23px 20px 20px;
}

.nm-md-highlight-bl-el-hl {
	display: block;
}

.nm-md-highlight-bl-el-hl::after {
	clear: both;
	content: "";
	display: block;
}

.nm-md-highlight-bl-el-text {
	padding-right: 20px;
}

.nm-md-highlight-bl-align-right .nm-md-highlight-bl-el-text {
	padding-left: 20px;
	padding-right: 0;
}

.nm-md-highlight-bl-el-img,
.nm-md-highlight-bl-el-text {
	box-sizing: border-box;
}

.nm-md-highlight .nm-el-lk-01 {
	margin-bottom: 4px;
	margin-top: 10px;
}

.nm-md-highlight .nm-el-pg-02 {
	margin: 14px 0 10px;
}

.nm-md-highlight-bl-el-img a:hover img {
	opacity: .7;
}

/*	module - highlights teaser - breakpoints for responsiveness
============================================================================= */

@media all and (max-width: 1024px) {
	.nm-state-is-responsive .nm-md-highlight {
		width: 100%;
	}
}

@media all and (max-width: 749px) {
	.nm-state-is-responsive .nm-md-highlight-container {
		width: 100%;
	}

	.nm-state-is-responsive .nm-md-highlight::after {
		display: none;
	}

	.nm-state-is-responsive .nm-md-highlight-container:first-child .nm-md-highlight-bl:first-child {
		margin-top: 0;
	}

	.nm-state-is-responsive .nm-md-highlight-bl-el-text,
	.nm-state-is-responsive .nm-md-highlight-bl-right .nm-md-highlight-bl-el-text,
	.nm-state-is-responsive .nm-md-highlight-bl-align-right .nm-md-highlight-bl-el-text {
		padding: 0;
	}
}

@media all and (min-width: 520px) and (max-width: 749px) {
	.nm-state-is-responsive .nm-md-highlight-bl {
		border-top: 1px solid #d0d3d4;
		margin-top: 80px;
	}

	.nm-state-is-responsive .nm-md-highlight-bl.counter-2 {
		margin-top: 72px;
	}

	.nm-state-is-responsive .nm-md-highlight-container .nm-md-highlight-bl:first-child {
		margin-top: 80px;
	}

	.nm-state-is-responsive .nm-md-highlight .nm-md-highlight-bl-el-hl {
		margin-bottom: 20px;
	}

	.nm-state-is-responsive .nm-md-highlight .nm-md-highlight-bl-no-img .nm-md-highlight-bl-el-hl {
		margin-bottom: 14px;
	}

	.nm-state-is-responsive .nm-md-highlight .nm-c2-d {
		margin-top: 0;
	}

	.nm-state-is-responsive .nm-md-highlight-container:first-child .nm-md-highlight-bl:first-child {
		border: none;
		text-align: left;
		width: 100%;
	}

	.nm-state-is-responsive .nm-md-highlight-container:first-child .nm-md-highlight-bl:first-child .nm-md-highlight-bl-el-text {
		width: 100%;
	}

	.nm-state-is-responsive .nm-md-highlight-bl-el-img,
	.nm-state-is-responsive .nm-md-highlight-bl-el-text {
		float: left;
		text-align: left;
		width: 50%;
	}

	.nm-state-is-responsive .nm-md-highlight-bl .nm-el-lbl-03,
	.nm-state-is-responsive .nm-md-highlight-bl .nm-md-highlight-bl-el-img,
	.nm-state-is-responsive .nm-md-highlight-container-start-left .nm-md-highlight-bl:nth-child(2n) .nm-el-lbl-03,
	.nm-state-is-responsive .nm-md-highlight-container-start-left .nm-md-highlight-bl:nth-child(2n) .nm-md-highlight-bl-el-img {
		float: right;
		text-align: right;
	}

	.nm-state-is-responsive .nm-md-highlight-bl .nm-md-highlight-bl-el-img,
	.nm-state-is-responsive .nm-md-highlight-container-start-left .nm-md-highlight-bl:nth-child(2n) .nm-md-highlight-bl-el-img {
		padding-left: 15px;
		padding-right: 0;
	}

	.nm-state-is-responsive .nm-md-highlight-bl .nm-md-highlight-bl-el-text,
	.nm-state-is-responsive .nm-md-highlight-container-start-left .nm-md-highlight-bl:nth-child(2n) .nm-md-highlight-bl-el-text {
		padding-left: 0;
		padding-right: 15px;
	}

	.nm-state-is-responsive .nm-md-highlight-bl:nth-child(2n) .nm-el-lbl-03,
	.nm-state-is-responsive .nm-md-highlight-bl:nth-child(2n) .nm-md-highlight-bl-el-img,
	.nm-state-is-responsive .nm-md-highlight-container-start-left .nm-md-highlight-bl:nth-child(2n+1) .nm-el-lbl-03,
	.nm-state-is-responsive .nm-md-highlight-container-start-left .nm-md-highlight-bl:nth-child(2n+1) .nm-md-highlight-bl-el-img {
		float: left;
		text-align: left;
	}

	.nm-state-is-responsive .nm-md-highlight-bl:nth-child(2n) .nm-md-highlight-bl-el-img,
	.nm-state-is-responsive .nm-md-highlight-container-start-left .nm-md-highlight-bl:nth-child(2n+1) .nm-md-highlight-bl-el-img {
		padding-left: 0;
		padding-right: 15px;
	}

	.nm-state-is-responsive .nm-md-highlight-bl:nth-child(2n) .nm-md-highlight-bl-el-text,
	.nm-state-is-responsive .nm-md-highlight-container-start-left .nm-md-highlight-bl:nth-child(2n+1) .nm-md-highlight-bl-el-text {
		padding-left: 15px;
		padding-right: 0;
	}

	.nm-state-is-responsive .nm-md-highlight-bl-no-img .nm-md-highlight-bl-el-text {
		padding: 0 !important;
		width: 100%;
	}
}

@media all and (max-width: 520px) {
	.nm-state-is-responsive .nm-md-highlight .nm-el-lbl-03 {
		display: block;
	}

	.nm-state-is-responsive .nm-md-highlight-bl {
		text-align: left;
	}

	.nm-state-is-responsive .nm-md-highlight-container .nm-md-highlight-bl:first-child {
		margin-top: 73px;
	}
}
