/*******************************************************************

	.nm-module-interactive-image-rotation
	scopes: nm-module-interactive-image-rotation & nm-module-interactive-image-rotation-annotated
	mobile-first

*******************************************************************/

.nm-module-interactive-image-rotation {
	display: block;
}

[page-type="widescreen"] .nm-module-interactive-image-rotation {
	padding: 0 4%;
}

/* module headline */

.nm-module-interactive-image-rotation .nm-rotation-headline {
	font-weight: var(--font-weight-bold);
	margin-bottom: 30px;
}

@media all and (min-width: 1440px) {
	.nm-module-interactive-image-rotation .nm-rotation-headline {
		margin-bottom: 36px;
	}
}

@media all and (min-width: 1920px) {
	.nm-module-interactive-image-rotation .nm-rotation-headline {
		margin-bottom: 42px;
	}
}

/* annotation container */

.nm-module-interactive-image-rotation .annotation-container {
	box-sizing: border-box;
}

@media all and (min-width: 750px) {
	.nm-module-interactive-image-rotation .annotation-container {
		float: left;
		padding: 0 12px 0 0;
		width: 40%;
	}

	.nm-module-interactive-image-rotation .annotation-container-is-right-aligned {
		padding: 0 0 0 12px;
	}

	.nm-1col .nm-module-interactive-image-rotation .annotation-container,
	.nm-2col .nm-module-interactive-image-rotation .annotation-container {
		float: none;
		padding: 0;
		width: 100%;
	}
}

@media all and (min-width: 1177.7px) {
	.nm-2col .nm-module-interactive-image-rotation .annotation-container {
		float: left;
		padding: 0 12px 0 0;
		width: 40%;
	}

	.nm-2col .nm-module-interactive-image-rotation .annotation-container-is-right-aligned {
		padding: 0 0 0 12px;
	}
}

/* annotation components */

.nm-module-interactive-image-rotation .annotation-headline {
	font-weight: var(--font-weight-bold);
	margin-bottom: 24px;
}

.nm-module-interactive-image-rotation .annotation-text {
	margin-bottom: 18px;
}

/* nm-slider-container */

.nm-module-interactive-image-rotation .nm-slider-container {
	box-sizing: border-box;
	position: relative;
	text-align: center;
}

@media all and (min-width: 750px) {
	.nm-module-interactive-image-rotation-annotated .nm-slider-container {
		float: left;
		padding: 0 12px 0 0;
		width: 60%;
	}

	.nm-module-interactive-image-rotation-annotated .nm-slider-container-is-right-aligned {
		float: right;
		padding: 0 0 0 12px;
	}

	.nm-2col .nm-module-interactive-image-rotation-annotated .nm-slider-container,
	.nm-1col .nm-module-interactive-image-rotation-annotated .nm-slider-container {
		float: none;
		padding: 0;
		width: 100%;
	}
}

@media all and (min-width: 1177.7px) {
	.nm-2col .nm-module-interactive-image-rotation-annotated .nm-slider-container {
		float: left;
		padding: 0 12px 0 0;
		width: 60%;
	}

	.nm-2col .nm-module-interactive-image-rotation-annotated .nm-slider-container-is-right-aligned {
		float: right;
		padding: 0 0 0 12px;
	}
}

.nm-module-interactive-image-rotation .nm-rotation-inner-container {
	display: inline-block;
	text-align: center;
}

/* nm-rotation-image */

.nm-module-interactive-image-rotation .nm-rotation-image-container {
	position: absolute;
	top: 0;
	z-index: 1;
}

.nm-module-interactive-image-rotation .nm-rotation-image-container-is-active {
	position: relative;
	z-index: 2;
}
/* controller */

.nm-module-interactive-image-rotation .nm-rotation-control-container {
	display: inline-block;
	height: 26px;
	margin: 24px 0 30px;
	position: relative;
	width: 100%;
}

.nm-module-interactive-image-rotation .nm-rotation-control-container::before {
	/* grey full process line */
	background: #b3b3b3;
	bottom: 0;
	content: "";
	display: block;
	height: 2px;
	margin: auto;
	position: absolute;
	top: 0;
	width: 100%;
}

.nm-module-interactive-image-rotation .nm-rotation-control {
	background: #fff;
	border: 1px solid #808080;
	border-radius: 50%;
	bottom: 0;
	cursor: move;
	height: 26px;
	margin: auto;
	position: absolute;
	top: 0;
	width: 26px;
	z-index: 2;
}

.nm-module-interactive-image-rotation .nm-rotation-control:focus,
.nm-module-interactive-image-rotation .nm-rotation-control:hover {
	border-color: #000;
	outline: 0;
}

.nm-module-interactive-image-rotation .nm-rotation-control-is-circular {
	left: calc(50% - 13px);
}

.nm-module-interactive-image-rotation .nm-rotation-control-past-line {
	/* black past process line */
	background: #000;
	content: "";
	display: block;
	height: 2px;
	margin-top: 12px;
	position: absolute;
	right: 26px;
}
