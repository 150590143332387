.nm-module-headline-combined:not(.legacy) {
	font-weight: 400;
	color: #333;
	text-decoration: none;
	letter-spacing: -.015em;
	font-family: AudiType, sans-serif;
	font-stretch: 130%;
	font-style: normal;
	font-size: 28px;
	line-height: 44px;
	margin: 0px 96px 12px;
}

.legacy.nm-module-headline-combined {
	font-weight: var(--font-weight-bold);
	margin: 0 0 var(--space-m);
}

[page-type="widescreen"] .legacy.nm-module-headline-combined {
	margin: 0 4% var(--space-m);
}

body:not(.nm-layer-fallback) [data-theme=dark] .nm-module-headline-combined {
	color: var(--color-white);
}

.nm-module-headline-combined.nm-bold {
	font-weight: 700;
}

h1.nm-module-headline-combined.nm-spacing-top,
h2.nm-module-headline-combined.nm-spacing-top,
h3.nm-module-headline-combined.nm-spacing-top,
h4.nm-module-headline-combined.nm-spacing-top {
	margin-top: 24px;
}

h1.nm-module-headline-combined:not(.legacy) {
	color: #000;
}

@media screen and (min-width: 0px) {
	h1.nm-module-headline-combined,
	h2.nm-module-headline-combined,
	h3.nm-module-headline-combined,
	h4.nm-module-headline-combined {
		margin: 0px 16px 12px;
	}

	h1.nm-module-headline-combined:not(.legacy) {
		font-size: 28px;
		line-height: 44px;
	}

	h2.nm-module-headline-combined:not(.legacy) {
		font-size: 24px;
		line-height: 36px;
	}

	h3.nm-module-headline-combined {
		font-size: 20px;
		line-height: 32px;
	}

	h4.nm-module-headline-combined {
		font-size: 16px;
		line-height: 24px;
	}
}

@media screen and (min-width: 375px) {
	h1.nm-module-headline-combined,
	h2.nm-module-headline-combined,
	h3.nm-module-headline-combined,
	h4.nm-module-headline-combined {
		margin: 0px 28px 12px;
	}
}

@media screen and (max-width: 479px) {
	.legacy.nm-module-headline-combined {
		h2.legacy.nm-module-headline-combined {
			display: block;
			word-break: break-word;
		}
	}
}

@media screen and (min-width: 768px) {
	h1.nm-module-headline-combined,
	h2.nm-module-headline-combined,
	h3.nm-module-headline-combined,
	h4.nm-module-headline-combined {
		margin: 0px 40px 12px;
	}

	h1.nm-module-headline-combined:not(.legacy) {
		font-size: 28px;
		line-height: 44px;
	}

	h2.nm-module-headline-combined:not(.legacy) {
		font-size: 24px;
		line-height: 36px;
	}

	h3.nm-module-headline-combined {
		font-size: 20px;
		line-height: 32px;
	}

	h4.nm-module-headline-combined {
		font-size: 16px;
		line-height: 24px;
	}
}

@media screen and (min-width: 1024px) {
	h1.nm-module-headline-combined,
	h2.nm-module-headline-combined,
	h3.nm-module-headline-combined,
	h4.nm-module-headline-combined {
		margin: 0px 60px 12px;
	}

	h1.nm-module-headline-combined:not(.legacy) {
		font-size: 36px;
		line-height: 52px;
	}

	h2.nm-module-headline-combined:not(.legacy) {
		font-size: 28px;
		line-height: 44px;
	}

	h3.nm-module-headline-combined {
		font-size: 20px;
		line-height: 32px;
	}

	h4.nm-module-headline-combined {
		font-size: 16px;
		line-height: 24px;
	}
}

@media screen and (min-width: 1440px) {
	[page-type="widescreen"] .legacy.nm-module-headline-combined {
		margin: 0 calc(100% - 66% - 8% - 4%) var(--space-m) calc(8% + 4%);
	}

	h1.nm-module-headline-combined,
	h2.nm-module-headline-combined,
	h3.nm-module-headline-combined,
	h4.nm-module-headline-combined {
		margin: 0px 96px 12px;
		max-width: 1920px;
	}

	h1.nm-module-headline-combined:not(.legacy) {
		font-size: 40px;
		line-height: 60px;
	}

	h2.nm-module-headline-combined:not(.legacy) {
		font-size: 32px;
		line-height: 48px;
	}

	h3.nm-module-headline-combined {
		font-size: 24px;
		line-height: 36px;
	}

	h4.nm-module-headline-combined {
		font-size: 18px;
		line-height: 28px;
	}
}

@media screen and (min-width: 1920px) {
	h1.nm-module-headline-combined:not(.legacy) {
		font-size: 44px;
		line-height: 68px;
	}

	h2.nm-module-headline-combined:not(.legacy) {
		font-size: 36px;
		line-height: 52px;
	}

	h3.nm-module-headline-combined {
		font-size: 24px;
		line-height: 36px;
	}

	h4.nm-module-headline-combined {
		font-size: 20px;
		line-height: 32px;
	}
}

.nm-2col .legacy.nm-module-headline-combined,
.nm-1col .legacy.nm-module-headline-combined,
[page-type="widescreen"] .nm-2col .legacy.nm-module-headline-combined,
[page-type="widescreen"] .nm-1col .legacy.nm-module-headline-combined,
[page-type="widescreen"] .nm-layer .legacy.nm-module-headline-combined,
[page-type="widescreen"] .modal-layer .legacy.nm-module-headline-combined {
	margin: 0 0 var(--space-m);
}