.nm-module {
	margin: 0 0 80px;
}

.nm-module img,
.nm-module-list img {
	height: auto;
	width: 100%;
}

.nm-module-list {
	margin-left: -7%;
}

/* todo: core-ci */
.nm-svg-icon {
	background-position: 0 0;
	background-repeat: no-repeat;
	background-size: contain;
	display: block;
	height: 50px;
	padding-left: 60px;
	position: relative;
	width: 50px;
}

/* todo: replace with svg and relocate to a better place */
.nm-rolling-noise-3::before {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAUCAMAAABRYFY8AAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAKjUExURUxpcQAAAAAAAAAAAACS1ACS1QCR1QAAAACR1ACS1AAAAACS1QCR1ACM2ACS1ACS1QCR1P///wCR1QAAAACS1QAAAACS1QAAAACS1ACS1QCS1ACS1ACR1QAAAAAAAACR1ACR1ACR1QAAAACR1QAAAACR1ACS1ACS1QAAAAAAAACS1QCR1AAAAACS1P///wCV0wAAAACS1ACT1AAAAAAAAAAAAACP0wCR1QCS1AAAAAAAAAAAAACS1QAAAACR1QAAAACR1AAAAAAAAAAAAP///wCS1ACR1ACR1AAAAAAAAAAAAAAAAP///wAAAAAAAAAAAAAAAAAAAACR1QCR1AAAAAAAAACP1QCS1ACS1QCS1ACO1ACP1ACR1ACM0wCR1QCR1QAAAAAAAAAAAAAAAACS1QCR1ACS1ACR1AAAAACS1ACQ1QCS1ACS1QCR1QAAAAAAAAKM0wAAAACK0QAAAAAAAAAAAAAAAO3t7QCS1ACS1ACS1ACR1AAAAAAAAAAAAP///wCR1QCR1QCN0wWQ1X///8Pk9QCS1ACS1AAAAACf3wAAAACL0wAAAAOT1gCO1ACP1ACR1QCR1P///8C/vwAAAP///////wAAAAAAAByb2M7p99Ds9wCI0e/3/ACT0wAAAAAAAACJ1+r2+3/I7CCc2wCR1QCR1QCR1QCR1QCR1ACU1QCS1QCR1QCS1QAAAAAAAMnEwf///+33/P///+Xz+8/KxwAAAAAAAP///wAAANfT0AAAANjSzsC8u8nJyQAAAAAAAAAAANjSzwAAAACS1QCN037M8////2O75p/U8Mbs/3LE6aHX8eLy+4bK7OD1+6bT7qDV8HTG8Vaz4tHp9iii3C2k3QCF0AB9zRKX1wCM06jg+3bG8QCH0BOV18bm9s/r+A7sWG4AAADDdFJOUwCkhgHf77HJ/H0DhpUU/ePUAnT+w/H7/J3R5I/pshRIxuKJkzbZ8fRIP3x5uKIaHU2LTsE8eynzsH8wns+svwe6rghjsHjs4FPP2S5hk0er0cyOKs6iN9r5lt77p3r6rD3gzdSU5a6XDGAl0+HujCB7DdD0RP1Fwce1wG0kQEJsuIf85wLTwfYvCIOCj+icMK27BsnDATV9bYX5pNP6R+sbDeUO6pp7n4DeK3bnuYW+v5D7msm4K6rE4bUlstDDvE90sx1ZKCsAAAFtSURBVBjTY2CgGsjiTJFQ5g4KhvG5/B3tgVR/g0iaQHzckWxeBoZJ06Yy1BSGHnZmYFjIqdqYk84ikKnezSC4cQfDAlHGfNEAJ4ZVbBMlJKU5RKr75izefHzfNjnZw3V5h/0Y5FcqK0xg41Prnblky4Pbe3duVznMqHFYiWE1ywpFtqojTacXbbp/785WxsMa++d3FMsyLFujJcUjeeToxSuXb949dkjz8EEl4drkQIa1rNpqqrlHjp65cOPorWO7dh9eN/1wWZInw3JWbZm21CNHT549d/X6sT0HDs/oKU3wYmdYqq7Fz6LIF32iddb5a5c2rBfXZE/0FrdlEBKTVgh3F5OJKmiePffU5CldJYd9PQ5bMsxrr4zkkDriIB9T1FJ/vJwhI+SwivVhE4bOighXVvMj/NwcsQwMPi4McuymVsLGzAwMYW52FkeAwAYYPgx6ggxczDqHDUDhZ8ZpZKjLI6QPD2AmJtIjBQCZNn0/CCmdVAAAAABJRU5ErkJggg==");
}

.nm-rolling-noise-2::before {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAUCAMAAABRYFY8AAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAALKUExURUxpcQCS1QCR1ACM2ACS1QCR1QAAAACR1ACS1ACS1ACS1ACS1QAAAACR1P///wCR1QCS1QCS1QCS1ACS1QCS1ACS1ACR1QCR1ACR1AAAAACR1QCR1QCR1ACS1QCS1AAAAAAAAACS1QAAAACR1AAAAACS1P///wCV0wCS1ACT1EhISACP0wCR1QCS1AAAAFFRUQCS1QCR1YqKigCR1P///wCR1ACR1ACS1P///wAAAACR1ACR1Tc3NwCP1QCS1ACS1ACS1QCR1ACO1ACP1ACM0wCR1QCR1QAAAB4eHgAAAACS1YSDgwAAAACR1ACS1KioqC0tLQCR1GdlZWlpaQCQ1QAAAACS1ACS1ACR1QCS1QKM0wCK0UlJSQAAAK+vr+3t7QCS1ACS1AAAADU1NbKysurq6tLS0ri4uK2trTw8PJqamoyMjBsbGwCS1ACR1KSjowCR1X9/fwCR1X5+fv///+Dg4CkpKczMzAAAAACN08HBwcPk9QAAACgoKAWQ1X///wCS1ACS1ACf3wCL0wCO1AOT1nx8fA8PDwAAAACR1QCP1DMzM+fm5gAAAAAAAAAAAAAAAAAAACMjIwCR1P///8C/vwAAAP///////xUVFc7p9xyb2NDs9wCI0QAAAO/3/ACT0wAAAAAAAAAAAACJ1+r2+yCc23/I7ACU1QAAAACS1QCR1QCR1QCR1QCR1QAAAACR1QAAAACR1ACS1QAAAAAAAAAAAMnEwe33/P///+Xz+////9jSzv///wAAAMC8u9jSzwAAAAAAAAAAAMnJyQAAANfT0M/KxwAAAAAAAAAAAACS1QAAAACN0////37M8/39/fLx8fn5+WO75v7+/p/U8Pv7+8bs/+Ly+3LE6aHX8YbK7KDV8KbT7uD1+3TG8dHp9i2k3QB9zRKX1yii3Faz4gCF0ACM08bm9s/r+ACH0HbG8ajg+xOV19CL3VkAAADLdFJOUwCGlRTvsQP8fd/948nUAnTD+53R5I/pxkgU4pPZ9PH8Nnw/eaSiGh2LToYp87ABhs+/uLqw7OB4YXsqjk432pb5p977eqz6zjIHlK8C5a7CPZeArCUMYNPu4XvQZQnMwce1k0jP/NvSyVStoC7AbbKHjLijbPI91eD8zNNERecCwfYIgpzojSENrTBK9iSjQoOPQLsGycM1ATD5haTT8fpHff5tDeXqDiuydpp754DrnxveuYmFvr/7kMmassQr0LN04SXDT7W4SLyq5NwVyAAAAW5JREFUGNNjYKAaCOHwEpJmtLaC8dmCvT2BVFeRgB+vq9vpcGYGhinTZzD0dhSfi2FgWMIhUxAZyMkbJtvMwLdzL8PGeX3tpemxDCtYJwsJi7IL5LXMXb773JF9kivP9LddSGaQ2CQt3snKJdc6e9mul2/2HzqlcEal+1ohwyrOdVKsuacrby7d8eLt6+MqZ06cXD+zJ59hzVpFESbh02fvPHj6+NX5A0pnDm5ZNKk8jmEzi7KcTMTps7fuPjr7/Pyxw2e2LjxTW5XCsIFFWazB9/TZqzduP3l2fs/RM4snypelZTCsllXk5pTicr5cPevew/vbt8kr8VTkpGYx8AuKituZCYo5RdXNmX996rQJNWeSMq8EMSxoTLRnFzltLOEYXV9yLp4h1P2MQvalAIamBAdzFp3T3IzsLgwMFiYMkjyWHhf9fRgYbE0NdU8DgQEwfBjU+BjY9Iwu2IDCT5tDU0OViV8dHsBa+qRHCgCn0oWmkCadbQAAAABJRU5ErkJggg==");
}

.nm-rolling-noise-1::before {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAUCAMAAABRYFY8AAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAALcUExURUxpcQCS1QCS1QCR1QCR1QCR1ACS1ACR1ACS1ACR1QCS1QCR1ACM2ACS1ACS1QCR1ACR1QCS1QCS1QCS1ACR1f///wCR1ACR1QCR1AAAAACS1AAAAAAAAACS1QCR1ACS1ACV0wCS1ACT1ACP0wCR1QCS1ACS1QCR1f///wCR1ACS1QCR1ACR1ACS1ACR1ACR1QCP1QCS1ACS1ACP1ACM0wCO1ACR1ACR1QCR1ACR1ACR1ACS1P///wCS1ACS1ACQ1f///wCR1QCS1QCS1ACK0QKM0wCS1AAAAACR1FlZWQCS1Hh2dgCR1QCN0wCR1QCS1AWQ1QCL08Pk9QCO1AOT1n///wCR1ACR2gAAAP///wCS1ACR1QCP1P///z09PQCR1NfX1wAAAJmYmf///87p99Ds9xyb2ACI0YWFhbGxsQ8PDygoKFFRUQCT03FxcSCc23/I7Or2++/3/ACJ1////3Z2dgsLC76+vktLSACR1QCR1QCR1ACS1QCS1QCU1QCR1QCR1QCR1f///05OTnZ2dr6+vtvb27++vpiWliYmJqKjogAAAOXz++33/P///6Ojo////wAAAOnp6H9/f4qKihMTE0JCP7m5udLS0ru7u5KSkn5+fgAAABEREQAAAMnEwG1tbbm5udvb20M/P8nJyRAQEObm5iEhIaioqFlZWWZmZhkWFjIyMgAAAD4+O3p6egAAAAAAANjSzwAAAAAAAL+9vIKCgomJiBEREcHBwXp6egAAAAAAAAAAAAAAANjSzwAAAAAAAAAAAAAAAAAAAJycnNDKxwAAAF5eXtjSz3FxcQ8PDwAAAACS1f///wAAAACN037M8+Pj42O75p/U8OLy+6HX8XLE6aDV8OD1+6bT7obK7Mbs//z8/PT09Cii3C2k3dHp9gB9zVaz4gCF0M/r+Mbm9nTG8e3s7QCM0xKX1+7u7ajg+xOV1+Dg4Orq6gCH0HbG8fDw8OXl5SxNQ/EAAADNdFJOUwDv9LGTxt/8ffqGlRT949R0w9Hk6QJI4tkE8QEGfHmiHYtOKfOwz78auvvs4HgqjjeW2vt63qesnOWXrmHTYCWw7uGP0Hu1Am2JwIW4/IfB54LTnOgCkAcLbJ2tMAZXu8IWvTX5pIXTvcoQRXFHu+oO5foNAc8X21F7n965diuA55qQforV5vGrPLMVyfuauMMo9ZKrQGDQ3szRlRIs+cya4uRMzT36TMxNqk9WkE2cScuxIVf5tvM86q19rrBZs09M/ngjwL4wf7KWQIguEP4vAAABcUlEQVQY02NgoBqw52CxYOHS14PxZfIyIoBUjI+gNb8551lbHgaG2OQS6YlzppzLYWAo5pDwc7Ji47djCmQQbZrAsHn7qrmTyrMY6pjjo4Sc2QU9gtML+s4t2XJk26fVs89VMIhVs7BGM/NKhaTl9zx9s/X83tOXdq05N5mhlq1enNnrrO/1wt5nbz8e23P+wNGdO5b1M1Q2yApzC529cOPh41cfLp48eH73qc/rZrQy1DDKSUk4nr1w7dajCy8v7j9+/syJ9ytntjFUMcqJ+FuevXDl6u37Ty5u2nf+8Pp3ixdNZ2hkkuVjE+c1vhyUcvPB8/kbD21YsXDpiy4GAc4wViMdLRFT14DUojvt89Yuf71g2rkWhlwmN0N24bOaYmYuoZHnEsq6Z12a2nE3myGclVOXUeUsHxe7CQODjQNDYmlz571MGQYGA2111bNAoAYMHwYNUQZJyaRzcaDwU+ZQUpTnFlCAhae7p7c0yZECAGVqisNWgCqGAAAAAElFTkSuQmCC");
}
