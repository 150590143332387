.nm-panel-tabs {
	margin-bottom: 50px;
}

@media screen and (max-width: 567px) {
	.nm-panel-tabs {
		position: relative;
		text-align: left;
		width: 100%;
	}

	.nm-panel-tabs input[type="radio"] {
		display: none;
		position: absolute;
	}

	.nm-panel-tabs [class*="nm-panel-tab-"] {
		background-color: #e9e7ea;
		border-bottom: 1px solid #d0d3d4;
		display: block;
		font-family: AudiTypeExtended, Verdana, sans-serif;
		font-weight: var(--font-weight-bold);
		margin-bottom: 0;
		margin-left: -4.24%;
		margin-right: -4.24%;
		padding: 24px 0 24px 4%;
		position: relative;
		width: auto;
	}

	.nm-panel-tabs label.nm-panel-tab-collapse {
		display: none;
	}

	.nm-panel-tabs label.nm-panel-tab-expand {
		display: block;
	}

	.nm-panel-tabs .nm-module-wrapper {
		max-height: 0;
		opacity: 0;
		overflow: hidden;
		position: relative;
		transition: all .6s ease-out 0s;
		width: 100%;
	}

	.nm-panel-tabs [class*="nm-panel-tab-"]::before {
		background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAKCAYAAABi8KSDAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo3ODIwMkZGN0YwQjIxMUU0OUQxQkM5Qjg4ODExNjRCMiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo3ODIwMkZGOEYwQjIxMUU0OUQxQkM5Qjg4ODExNjRCMiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjc4MjAyRkY1RjBCMjExRTQ5RDFCQzlCODg4MTE2NEIyIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjc4MjAyRkY2RjBCMjExRTQ5RDFCQzlCODg4MTE2NEIyIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+MEw/TwAAAIRJREFUeNpiYGBgCGIgDoSDiNdAHEKEwncgxh8g/ozHBpBBX4D4P4hTDWV8hVmFBCKA+BtUvgYmWAUVANkQBhULQzIRpJARJMgClayBSrwH4klQNyKbyA0zmRFKV0IVwHA1VJwNiJmweQhmQx2Uz4IvmEAmWAExKxAzExkHcKfBAUCAAQAPox59eFmkagAAAABJRU5ErkJggg==");
		background-position: center center;
		background-repeat: no-repeat;
		content: "";
		display: inline-block;
		height: 10px;
		padding: 27px 10px;
		position: absolute;
		right: 0;
		top: 0;
		width: 11px;
	}

	.nm-panel-tabs label.nm-panel-tab-collapse::before {
		background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAKCAYAAABi8KSDAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo4QTQ0QTBCNkYwQjIxMUU0OUQxQkM5Qjg4ODExNjRCMiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo4QTQ0QTBCN0YwQjIxMUU0OUQxQkM5Qjg4ODExNjRCMiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjc4MjAyRkZERjBCMjExRTQ5RDFCQzlCODg4MTE2NEIyIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjc4MjAyRkZFRjBCMjExRTQ5RDFCQzlCODg4MTE2NEIyIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+wtWy+wAAALBJREFUeNp80D0KAjEQhuFEg+B57DyGJ7AQ1MZ+1Ub8uYCFnWDpIt7Cbu+ihT8gq+s78lm4RAce2J1MJpP4zDVcKTwKF4lK6b+KgGZk7SthRQ8kOGAYKzY15BhhorWpNn5GexfWcccYMxyxxAkL5e0OwYqvSlinC3oYoIuz8jZSHnS0JW7oINXRqY5fY27fnqcr1LGNXeTFWtjYuEEz9n8UWux1t1VQ4db9D2v0fAkwACq9JXO6Q98RAAAAAElFTkSuQmCC");
	}

	.nm-panel-tabs input:checked + [class*="nm-panel-tab-"] + .nm-panel-tab-collapse {
		display: block;
	}

	.nm-panel-tabs input:checked + .nm-panel-tab-expand {
		display: none;
	}

	.nm-panel-tabs [id^="panel-tab"]:checked + [class*="nm-panel-tab-"] + [class*="nm-panel-tab-"] + .nm-module-wrapper {
		max-height: 7000px;
		opacity: 1;
		overflow: visible;
		padding-top: 20px;
		transition: all .6s;
	}
}

@media screen and (min-width: 568px) {
	.nm-panel-tabs {
		display: flex;
		flex-wrap: wrap;
		position: relative;
	}

	.nm-panel-tabs::before {
		background-color: #d7dadb;
		content: "";
		height: 1px;
		left: 0;
		position: absolute;
		top: 44px;
		width: 100%;
	}

	.nm-panel-tabs input[type="radio"] {
		display: none;
		position: absolute;
	}

	.nm-panel-tabs .panel-tab-default:checked + input + [class*="nm-panel-tab-"] {
		border-bottom: 1px solid #fff;
		border-left: 1px solid #d7dadb;
		border-right: 1px solid #d7dadb;
		border-top: 1px solid #d7dadb;
		color: var(--color-progressive-red);
		z-index: 2;
	}

	.nm-panel-tabs .panel-tab-default:checked + input + [class*="nm-panel-tab-"] + [class*="nm-panel-tab-"] + .nm-module-wrapper {
		display: block;
	}

	.nm-panel-tabs [class*="nm-panel-tab-"]:hover,
	.nm-panel-tabs [id^="panel-tab"]:checked + [class*="nm-panel-tab-"] {
		color: var(--color-progressive-red);
	}

	.nm-panel-tabs [class*="nm-panel-tab-"] {
		border: 1px solid transparent;
		cursor: pointer;
		display: block;
		margin: 0;
		padding: 14px 20px;
		position: relative;
	}

	.nm-panel-tabs label.nm-panel-tab-collapse {
		display: none;
	}

	.nm-panel-tabs [id^="panel-tab"]:checked + [class*="nm-panel-tab-"] {
		border-bottom: 1px solid #fff;
		border-left: 1px solid #d7dadb;
		border-right: 1px solid #d7dadb;
		border-top: 1px solid #d7dadb;
		color: var(--color-progressive-red);
		z-index: 2;
	}

	.nm-panel-tabs .nm-module-wrapper {
		display: none;
		order: 1;
		overflow: hidden;
		padding-top: 25px;
		width: 100%;
	}

	.nm-panel-tabs [id^="panel-tab"]:checked + [class*="nm-panel-tab-"] + [class*="nm-panel-tab-"] + .nm-module-wrapper {
		display: block;
	}
}

@media screen and (min-width: 1024px) {
	.nm-panel-tabs .nm-module-wrapper {
		padding-top: 45px;
	}
}
